<!--LoginBAse-->
<div class="loginbase loginfrm" *ngIf="!isLoggedIn">
    <div class="loginbox">
        <span class="material-icons-outlined">close</span>
        <div class="leftpart">
            <strong>Welcome Back <a href="#">Sign into your Account</a></strong>
            <form name="form" (ngSubmit)="f.form.valid" #f="ngForm" novalidate>
                <div class="formwrap" >
                    <div class="wrap email">
                        <input type="text" placeholder="Enter User Name" [(ngModel)]="loginform.email" required
                            minlength="3" #email="ngModel" [ngModelOptions]="{standalone: true}" />
                        <span class="bottomline">
                        </span>
                        <div class="alert alert-danger" role="alert" *ngIf="email.errors && f.submitted">
                            Username is required!
                        </div>
                    </div>
                    <div class="wrap lock">
                        <input type="password" placeholder="Enter Password" [(ngModel)]="loginform.password" required
                            minlength="6" #password="ngModel" [ngModelOptions]="{standalone: true}" />

                        <span class="bottomline">
                        </span>
                        <div class="alert alert-danger" role="alert" *ngIf="password.errors && f.submitted">
                            <div *ngIf="password.errors.required">Password is required</div>
                            <div *ngIf="password.errors.minlength">
                                Password must be at least 6 characters
                            </div>
                        </div>
                    </div>
                    <div class="wrap"><label class="customcheck"><input type="checkbox"><span
                                class="checkmark"></span>Remember
                            Me</label>
                        <a href="#" class="forgot">Forgot Password?</a>
                    </div>
                    <div class="wrap">
                        <a class="loginbtn" (click)="loginUser()">Login</a>
                        <!-- <span class="registerline">Don't have an account?
                            <a href="#">Register here</a></span> -->
                    </div>
                    <div class="wrap">
                        <ul>
                            <li><a href="#">As voice artist</a></li>
                            <!-- <li><a href="#">As Customer</a></li>
                            <li><a href="#">As a Translator</a></li>
                            <li><a href="#">As a Content Writer</a></li> -->
                        </ul>
                    </div>
                </div>
            </form>
        </div>
        <div class="rightpart">
            <img src="/assets/images/loginimg.jpg">
            <div class="wemake">
                <img src="/assets/images/logo.png" alt="The Voice Mall">
                <!-- <strong>We Make Spectacular</strong> -->
                <p><b>Please sign in to view your dashboard and complete your profile. 
                    Our interactive registration pages are specially designed keeping in mind your comfort and ease.</b></p>
            </div>
            <div class="loginsocial">
                <span>Visit our Social Media Pages</span>
                <ul>
                    <li><a href="https://www.facebook.com/thevoicemall" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                    <li><a href="#"><i class="fa fa-google-plus" aria-hidden="true"></i></a></li>
                    <li><a href="https://www.linkedin.com/in/the-voice-mall-0b4614244/" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="loginoverlayer"></div>
</div>

<!--Voicepopup-->
<div class="loginbase ragisterfrm" *ngIf="!isSuccessful">
    <div class="loginbox">
        <span class="material-icons-outlined">close</span>
        <div class="leftpart">
            <strong>Registration</strong>
            <form name="form" (ngSubmit)="f.form.valid" #f="ngForm" novalidate>
                <div class="formwrap">
                    <div class="wrap">
                        <div class="wrap-divide">
                            <input type="text" placeholder="Enter First Name*" [(ngModel)]="registerform.username"
                                #username="ngModel" [ngModelOptions]="{standalone: true}" required minlength="3"
                                maxlength="20">
                            <div class="alert-danger" *ngIf="username.errors && f.submitted">
                                <div *ngIf="username.errors.required">Username is required</div>
                                <div *ngIf="username.errors.minlength">
                                    Username must be at least 3 characters
                                </div>
                                <div *ngIf="username.errors.maxlength">
                                    Username must be at most 20 characters
                                </div>
                            </div>
                        </div>
                        <div class="wrap-divide">
                            <input type="text" placeholder="Enter Last Name" [(ngModel)]="registerform.last_name"
                                #last_name="ngModel" [ngModelOptions]="{standalone: true}" required><span
                                class="bottomline"></span>
                        </div>
                    </div>
                    <!--<div class="wrap">
      <input type="text" placeholder="+91" required><span class="bottomline"></span>
      </div>-->
                    <div class="wrap">
                        <div class="wrap-divide">
                            <select #country name="country" id="country" (change)="onCountryChange($event)"
                                (ngModel)="registerform.country" [ngModelOptions]="{standalone: true}">
                                <option [value]="null">-- Select Your Country --</option>
                                <option *ngFor="let country of countries" [value]="country | json">
                                    {{ country.name }} {{"("+country.phonecode+")"}}
                                </option>
                            </select>
                        </div>
                        <div class="wrap-divide">
                            <input type="text" placeholder="Contact No*" [(ngModel)]="registerform.contact"
                                #contact="ngModel" [ngModelOptions]="{standalone: true}" minlength="8" maxlength="12"
                                required>
                            <div class="alert-danger" *ngIf="contact.errors && f.submitted">
                                <div *ngIf="contact.errors.required">Contact number is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="wrap">
                        <input type="text" placeholder="Enter Email ID*" [(ngModel)]="registerform.email"
                            #email="ngModel" [ngModelOptions]="{standalone: true}" required email
                            style="padding-left:0;">
                        <div class="alert-danger" *ngIf="email.errors && f.submitted">
                            <div *ngIf="email.errors.required">Email is required</div>
                            <div *ngIf="email.errors.email">
                                Email must be a valid email address
                            </div>
                        </div>
                    </div>
                    <div class="wrap">
                        <div class="wrap-divide">
                            <label>Password must have six(6) character*</label>
                            <input type="password" placeholder="Enter Password*" [(ngModel)]="registerform.password"
                                #password="ngModel" [ngModelOptions]="{standalone: true}" required minlength="6">
                            <div class="alert-danger" *ngIf="password.errors && f.submitted">
                                <div *ngIf="password.errors.required">Password is required</div>
                                <div *ngIf="password.errors.minlength">
                                    Password must be at least 6 characters
                                </div>
                            </div>
                        </div>

                        <div class="wrap-divide">
                            <input type="password" placeholder="Confirm Password*"><span class="bottomline"></span>
                        </div>
                    </div>
                    <!-- <div class="wrap">
                        <input type="text" placeholder="Company Name*" [(ngModel)]="registerform.company_name"
                            #company_name="ngModel" [ngModelOptions]="{standalone: true}" required
                            style="padding-left:0;">
                        <div class="alert-danger" *ngIf="company_name.errors && f.submitted">
                            <div *ngIf="company_name.errors.required">Company name is required</div>
                        </div>
                    </div> -->
                    <div class="wrap"><label class="customcheck"><input type="checkbox"><span class="checkmark"></span>I
                            agree
                            to The Voice Mall Terms of Service and Privacy Policy</label></div>
                    <div class="wrap">
                        <a class="loginbtn" (click)="registerUser()">Register</a>
                        <!-- <span class="registerline">Already registered?
                            <a href="#">Login here</a></span> -->
                    </div>

                </div>
            </form>
            <div class="alert alert-success" *ngIf="isSuccessful">
                Your registration is successful!
            </div>
        </div>
        <div class="rightpart">
            <img src="/assets/images/loginimg.jpg">
            <div class="wemake">
                <img src="/assets/images/logo.png" alt="The Voice Mall">
                <strong><span>Welcome to </span>The Voice Mall</strong>
                <p>You can register on The Voice Mall for free of cost and upload your voice samples. 
                    It’s as easy as it gets. So hurry up and <b>REGISTER NOW</b></p>
                   
            </div>
            <div class="loginsocial">
                <span>Visit our Social Media Pages</span>
                <ul>
                    <li><a href="https://www.facebook.com/thevoicemall" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                    <li><a href="#"><i class="fa fa-google-plus" aria-hidden="true"></i></a></li>
                    <li><a href="https://www.linkedin.com/in/the-voice-mall-0b4614244/" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="loginoverlayer"></div>
</div>

<!--bof:top-->
<div class="topbase">
    <div class="container">
        <ul class="signpart" *ngIf="isLoggedIn==false">
            <li><a class="sign" href="javascript:void(0)"><i class="fa fa-sign-in" aria-hidden="true"></i> Sign In</a>
            </li>
            <li class="borernone"><a class="ragis" href="javascript:void(0)"><i class="fa fa-user-circle-o"
                        aria-hidden="true"></i> Register</a></li>
        </ul>
        <ul class="signpart" *ngIf="isLoggedIn==true">
            <li class="borernone"><a class="ragis" (click)="logout()"><i class="fa fa-user-circle-o"
                        aria-hidden="true"></i>{{this.email}}LogOut</a>
                    </li>
                    <Button routerLink="/editprofile">My Profile</Button>
        </ul>
        <div class="registernav">
            <ul>
                <li><a class="voiceartist" href="javascript:void(0)">As a voice artist</a></li>
                <!-- <li><a class="voiceartist" href="javascript:void(0)">As a voice customer</a></li>
                <li><a class="voiceartist" href="javascript:void(0)">For Translation services</a></li>
                <li><a class="voiceartist" href="javascript:void(0)">For Content writing services</a></li> -->
            </ul>
        </div>
    </div>
</div>
<!--eof:top-->


<div id="maincontainer">

    <!--header-->
    <div class="container">
        <div class="voiceheader">
            <div class="logo"><a (click)="home()"><img src="/assets/images/logo.png" alt="The Voice Mall"></a></div>
            <!-- NavigagionContainer -->
            <div class="navcontainer">
                <!--Navigation-->
                <div class="wraphead">
                    <!--bof:message from cco-->
                    <!--eof:message from cco-->
                    <!--bof:navheader-->
                    <div class="navheader">
                        <div class="resize-pnl">
                            <!--bof:Navigation-->
                            <div class="navigation desktop-nav">
                                <ul class="drop-nav">
                                    <li><a (click)="home()" class="active home"><i class="fa fa-home fa-2x"
                                                aria-hidden="true"></i></a>
                                    </li>
                                    <li><a routerLink="/about">About Us</a></li>
                                    <li><a href="javascript:void(0)" class="showings2">Services <i
                                                class="fa fa-angle-down" aria-hidden="true"></i></a>
                                        <!-- <ul class="collection-drop">
                                            <li><a href="voiceover.html">Voice over</a></li>
                                            <li><a href="videoproduction.html">Video production</a></li>
                                            <li><a href="translation.html">Translation</a></li>
                                            <li><a href="contentwriting.html">Content writing</a></li>
                                        </ul> -->
                                    </li>

                                    <li><a routerLink="/howwework">How we work</a></li>
                                    <li><a routerLink="/contact">Contact</a></li>
                                </ul>
                            </div>
                            <!--eof:Navigation-->
                            <div class="clr"></div>
                        </div>
                    </div>
                    <!--eof:navheader-->
                    <!--bof:mobile navheader-->
                    <div class="mnavheader">
                        <div class="resize-pnl">
                            <div id="mySidenav" class="sidenav">
                                <a href="javascript:void(0)" class="closebtn" onClick="closeNav()">
                                    <span class="material-icons">
                                        close
                                    </span>
                                </a>
                                <div class="tophead">
                                    <!--bof:Navigation-->
                                    <div class="navigation mobile-nav">
                                        <!--bof:Mobile Navigation-->
                                        <ul class="drop">
                                            <li><a class="active" (click)="home()">Home</a></li>
                                            <li><a (click)="about()">About Us</a></li>
                                            <li><a href="javascript:void(0)" class="showings2">Services <i
                                                        class="fa fa-angle-down" aria-hidden="true"></i></a>
                                                <!-- <ul class="drop">
                                                    <li><a href="voiceover.html">Voice over</a></li>
                                                    <li><a href="videoproduction.html">Video production</a></li>
                                                    <li><a href="translation.html">Translation</a></li>
                                                    <li><a href="contentwriting.html">Content writing</a></li>
                                                </ul> -->
                                            </li>
                                            <li><a (click)="howwework()">How we work</a></li>
                                            <li><a (click)="contactus()">Contact</a></li>
                                        </ul>
                                    </div>
                                    <!--eof:Navigation-->
                                </div>
                            </div>

                            <div id="main">
                                <span style="font-size:30px;cursor:pointer" onClick="openNav()">&#9776;</span>
                            </div>
                            <div class="clr"></div>
                        </div>
                    </div>
                    <!--eof:Mobile navheader-->
                </div>
            </div>
        </div>
    </div>
</div>