<!-- Banner -->
<div class="dashboardbanner">
  <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
  <div class="bannerheading">Voice Poftfolio</div>
</div>

<div class="fullwidth bg_purple">
  <!--bof: myrecording-->
  <div class="w1200">
    <div class="pointgrid">
      <div class="pointcols">
        <span><i class="active" routerLink="/editprofile"></i></span>
        <strong>Personal Info</strong>
      </div>
      <div class="pointcols">
        <span><i class="active" routerLink="/editvoice"></i></span>
        <strong>Voice Profile</strong>
      </div>
      <div class="pointcols">
        <span><i routerLink="/pricedelivery"></i></span>
        <strong>Price, Delivery time &amp; Availability</strong>
      </div>
      <div class="pointcols">
        <span><i routerLink="/equipment"></i></span>
        <strong>Equipments &amp; Experience</strong>
      </div>
      <div class="pointcols">
        <span><i routerLink="/voicesample"></i></span>
        <strong>Voice &amp; Sample</strong>
       </div>   
    </div>
    <div class="wedget">
      <!-- bof:wedgetleft -->
      <app-sidebar></app-sidebar>
      <!-- bof:wedgetright -->
      <div class="wedgetright">
        <div class="wedgetform">
          <h3>Voice &amp; Portfolio</h3>
          <!--bof:formsection-->
          <div class="form1">
            <form class="formpanel" name="form" (ngSubmit)="f.form.valid" #f="ngForm" novalidate>

              <div class="formwrap" *ngIf="isEditVoice">
                <label>Languages<span>*</span></label>
                <p class="paragraph">There is no limit to the number of languages you can record in. You are allowed to select more than one.</p>
                <select data-placeholder="Choose Languages" multiple
                  [(ngModel)]="editVoiceProfileForm.language" [ngModelOptions]="{standalone: true}" required #language="ngModel">
                  <option [ngValue]="null" selected disabled>Select Language</option>
                  <option *ngFor="let data of languageList" [label]="data.language" [value]="data.language"></option>
                </select>
                <div class="alert alert-danger" *ngIf="language.errors && f.submitted">
                  <div *ngIf="language.errors.required">Language is required</div>
                </div>
              </div> 

              <!-- <div class="formwrap">
                <label>Languages<span>*</span></label>
                <p>Select language you can record in you may select than one</p>
                <select data-placeholder="Choose Languages" class="form-control" multiple>
                  <option *ngFor="let data of languageList" [label]="data.language" [value]="data.language">
                    {{ data.language }}
               </option>
              </select>
              </div> -->

              <div class="formwrap">
                <label>Categories<span>*</span></label>
                <p class="paragraph">Record in as many categories as you like.</p>
                <select data-placeholder="Choose Categories"
                  [(ngModel)]="editVoiceProfileForm.category_name" [ngModelOptions]="{standalone: true}" multiple #category_name="ngModel" required>
                  <option [ngValue]="null" selected disabled>Select Category</option>
                  <option *ngFor="let data of categoryList" [label]="data.category_name" [value]="data.category_name">
                  </option>
                </select>
                <div class="alert alert-danger" *ngIf="category_name.errors && f.submitted">
                  <div *ngIf="category_name.errors.required">Categories is required</div>
                </div>
              </div>

              <div class="formwrap">
                <label>Recording Setup <span>*</span></label>
                <p class="paragraph">Where do you make your recordings? Choose the best one for you.</p>
                <select [(ngModel)]="editVoiceProfileForm.recordingSetup" [ngModelOptions]="{standalone: true}"
                  multiple #recordingSetup="ngModel" required>
                  <option [ngValue]="null" selected disabled>Select Recording SetUp</option>
                  <option *ngFor="let data of recordingSetupList" [label]="data.recordingSetup"
                    [value]="data.recordingSetup"></option>
                </select>
                <div class="alert alert-danger" *ngIf="recordingSetup.errors && f.submitted">
                  <div *ngIf="recordingSetup.errors.required">Categories is required</div>
                </div>
              </div>

              <div class="formwrap">
                <label>Voice Ages</label>
                <p class="paragraph">Choose the age(s) for which you want to record. You may choose more than one.</p>
                <select [(ngModel)]="editVoiceProfileForm.age_range" [ngModelOptions]="{standalone: true}" multiple>
                  <option [ngValue]="null" selected disabled>Select Voice Age</option>
                  <option *ngFor="let data of voiceAgeList" [label]="data.age_range" [value]="data.age_range"></option>
                </select>
              </div>

              <div class="formwrap">
                <label>Voice Accents</label>
                <p class="paragraph">Choose from a range of accents to record in. Multiple options are available.</p>
                <select [(ngModel)]="editVoiceProfileForm.accent" [ngModelOptions]="{standalone: true}" multiple>
                  <option [ngValue]="null" selected disabled>Select Accent</option>
                  <option *ngFor="let data of accentList" [label]="data.accent" [value]="data.accent"></option>
                </select>
              </div>

              <div class="formwrap">
                <label>Roles</label>
                <p class="paragraph">Do you play any roles? Several options are available to you.</p>
                <select [(ngModel)]="editVoiceProfileForm.role_name" [ngModelOptions]="{standalone: true}" multiple>
                  <option [ngValue]="null" selected disabled>Select Role</option>
                  <option *ngFor="let data of voiceRoleList" [label]="data.role_name" [value]="data.role_name"></option>
                </select>
              </div>

              <div class="formwrap">
                <label>Style</label>
                <p class="paragraph">Is there a range of styles that you can record? More than one option may be selected.</p>
                <select [(ngModel)]="editVoiceProfileForm.style_name" [ngModelOptions]="{standalone: true}" multiple>
                  <option [ngValue]="null" selected disabled>Select Style</option>
                  <option *ngFor="let data of styleList" [label]="data.style_name" [value]="data.style_name"></option>
                </select>
              </div>

              <div class="formwrapfull">
                <div class="submitdiv">
                  <input type="submit" value="Save and Next" (click)="editVoiceProfile()">
                  <i class="fa fa-angle-right" aria-hidden="true"></i>
                </div>
                <strong style="top: 0;">Items marked with <span>*</span> are mendatory</strong>
              </div>
            </form>
            <!--eof:formsection-->

          </div>
          <!--eof: form-->
        </div>
      </div>
    </div>
    <!--eof: myrecording-->
  