import { Component, ElementRef, OnInit, VERSION, ViewChild } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { UserService } from '../_services/user.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Country}  from 'country-state-city';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  
  content?: string;
  loginform: any = {
    email: null,
    password: null
  };

  isLoggedIn = false;
  isLoginFailed = false;
  loginErrorMessage = '';

  roles: string[] = [];
  email: any;
  userName: any;
  contact: any;
  userid: any


  registerform: any = {
    username: null,
    last_name: null,
    company_name: null,
    email: null,
    password: null,
    country: null,
    contact: null,
    roles: null

  };

 
  isSuccessful = false;
  isSignUpFailed = false;
  registerErrorMessage = '';
  // countryList: any;
  // Countries!: Countries;

  @ViewChild('country')
  country!: ElementRef;
  name = 'Angular ' + VERSION.major;
  countries = Country.getAllCountries();
  selectedCountry: any;


  constructor(private authService: AuthService, private tokenStorage: TokenStorageService,
    private tokenStorageService: TokenStorageService, private userService: UserService, private router: Router,
     private toastr: ToastrService) { }

  ngOnInit(): void {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorage.getUser().roles;
    }

    // this.userService.getPublicContent().subscribe(
    //   data => {
    //     this.content = data;
    //   },
    //   err => {
    //     this.content = JSON.parse(err.error).message;
    //   }
    // );

    // this.getCountryList();

  }

  loginUser(): void {
    const { email, password } = this.loginform;
    this.authService.login(email, password).subscribe(
      data => {
        this.isLoggedIn = true;     
        this.tokenStorage.saveToken(data.accessToken);
        this.tokenStorage.saveUser(data);
        this.roles = this.tokenStorage.getUser().roles;
        this.toastr.success('Logged In Success','')
        this.router.navigate(['/editprofile']);  
      },
      err => {
        this.toastr.error('UserName or password is incorrect','')
        this.loginErrorMessage = err.error.message;
        this.isLoginFailed = true;
      }
    );
  }

  registerUser(): void {
    const { username, last_name, company_name, country, contact, roles, email, password, } = this.registerform;
    this.authService.registerUser(username, last_name, company_name, country, contact, roles, email, password).subscribe(
      data => {
        console.log(data);
        this.isSuccessful = true;
        this.isSignUpFailed = false;
        this.toastr.success('Registration Success','')
      },
      err => {
        this.toastr.error('Registration Failed','')
        this.registerErrorMessage = err.error.message;
        this.isSignUpFailed = true;
      }
    );
  }
  

  reloadPage(): void {
    window.location.reload();
  }


  logout(): void {
    this.tokenStorageService.signOut();
    this.toastr.success('Logout Success','')
    this.router.navigate(['/']);
    setTimeout(() => {
      {
        this.reloadPage();
      }
    },10);
  }

  // getCountryList() {
  //   this.userService.getCountryList().subscribe(data => {
  //     this.countryList = data;
  //     if (this.countryList !== null) {
  //       this.countryList.forEach((element: { country: string; currencyCode: string; iso: string; }) => {
  //       });
  //     }
  //   },
  //     err => {
  //       this.content = JSON.parse(err.error).message;
  //       console.log(this.content);
  //     }
  //   );
  // }


  home(){
    window.scroll(0,0);
    this.router.navigate(['/']);
    setTimeout(() => {
      {
        this.reloadPage();
      }
    },10);

  }


  about(){
    window.scroll(0,0);
    this.router.navigate(['/about']);
    setTimeout(() => {
      {
        this.reloadPage();
      }
    },10);

  }

  howwework(){
    window.scroll(0,0);
    this.router.navigate(['/howwework']);
    setTimeout(() => {
      {
        this.reloadPage();
      }
    },10);

  }

  contactus(){
    window.scroll(0,0);
    this.router.navigate(['/contact']);
    setTimeout(() => {
      {
        this.reloadPage();
      }
    },10);

  }

  


  onCountryChange($event: any): void {
    //this.states = State.getStatesOfCountry(JSON.parse(this.country.nativeElement.value).isoCode);
    this.selectedCountry = JSON.parse(this.country.nativeElement.value);
    this.registerform.country = JSON.parse(this.country.nativeElement.value).name;
    //this.cities = this.selectedState = this.selectedCity = null;
  }

}
