import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { BoardUserComponent } from './board-user/board-user.component';
import { BoardModeratorComponent } from './board-moderator/board-moderator.component';
import { BoardAdminComponent } from './board-admin/board-admin.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { FaqComponent } from './faq/faq.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { EquipmentExperienceComponent } from './equipment-experience/equipment-experience.component';
import { EditVoiceProfileComponent } from './edit-voice-profile/edit-voice-profile.component';
import { PriceDeliveryTimeComponent } from './price-delivery-time/price-delivery-time.component';
import { ThankYouMessageComponent } from './thank-you-message/thank-you-message.component';
import { TermsComponent } from './terms/terms.component';
import { PolicyComponent } from './policy/policy.component';
import { HowWeWorkComponent } from './how-we-work/how-we-work.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { VoiceSampleComponent } from './voice-sample/voice-sample.component';



const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'user', component: BoardUserComponent },
  { path: 'mod', component: BoardModeratorComponent },
  { path: 'admin', component: BoardAdminComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'editprofile', component: EditProfileComponent },
  { path: 'equipment', component: EquipmentExperienceComponent },
  { path: 'editvoice', component: EditVoiceProfileComponent },
  { path: 'pricedelivery', component: PriceDeliveryTimeComponent },
  { path: 'thankyou', component: ThankYouMessageComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'policy', component: PolicyComponent },
  { path: 'howwework', component: HowWeWorkComponent },
  { path: 'disclaimer', component: DisclaimerComponent },
  { path: 'sidebar', component: SidebarComponent },
  { path: 'voicesample', component: VoiceSampleComponent },

  { path: '', redirectTo: '', pathMatch: 'full' }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
