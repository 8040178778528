<!-- Banner -->

<div class="dashboardbanner" onsubmit="pop()">
    <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
    <div class="bannerheading">
        <h1>Terms</h1>
    </div>
</div>

<div class="fullwidth  bg_purple">
    <!--bof: Contact Us-->
    <div class="w1200">
        <div class="aboutus">

            <h3> The <strong>Voicemall</strong> Terms of Use</h3>

            <p>The Voicemall, a proprietary product of <strong>3S Studio Pvt Ltd </strong>has full ownership of this website and all the
            intellectual content that can be seen on this website. We have used pronouns like "we", "us" and "our" to
            collectively refer to The Voicemall as a single entity including all the employees and staff. On this
            website, we have used terms like "you", "user" or "users'' to refer to our artists/actors, customers, and
            visitors of our online ecosystem and services. In our process of building relationships with our users, we
            make sure that all the terms, conditions, and privacy policies are stated clearly and categorically on this
            website. Our users must agree to all these terms, conditions, and privacy policies. These terms, conditions,
            and policies will be automatically considered agreed if a user agrees to transact through our website or
            app.</p>

            <p>After visiting site, users can only transact (like buying or selling or some other kind of other
            transaction) through our website, if they agree to our terms and conditions. We expect all our users that
            they have carefully read and understood all the terms, conditions, and privacy policies before accessing or
            using any section of our website or indulging in any kind of transaction.</p>

            <p>These terms and conditions are subject to constant updation, especially after the launch of any new feature
            or service, any such updation will be reflected here on this page and we expect all our users (past,
            present, or future users) to go through this page accordingly. We have all the rights to change any section
            of these terms and conditions as we find necessary. Our users are advised to update themselves accordingly.</p>

            <h3> Legal Conclusions</h3>
            <p>We strongly condemn any illegal use of our services. No one is authorized to use our services for any kind
            of unauthorized usage. We have all the legal rights to completely end or forfeit your usage of our services
            if we find that you have violated our terms and conditions in any way. We strongly expect that you will not
            use any tool that might disrupt our services like any virus, malware, worms, etc. In case you do use such
            tools, we have all the legal rights to terminate your services and subscriptions and also take legal actions
            in accordance with Indian Cyber Law.</p>

            <p>Whenever a user registers on The Voicemall site as an actor/artist, he/she is strongly expected to declare
            the sole copyrights of any voiceover project that he/she provided and that happens through us, online or
            offline, will be of The Voicemall's end client. That user/artist/actor will have no legal rights over it.
            Every artist/actor is expected to use the project script as provided by the client for voiceover purposes
            only. No user can use that copyrighted script of the client for any other purposes besides as intended.</p>

            <p>Whenever an artist/actor registers on The Voicemall registration online portal, he/she declares that the
            intention of creating The Voicemall account is for professional use only. No user is allowed to upload any
            obscene, illegal, prohibited, copyrighted content on The Voicemall platform in any format. Any voice samples
            uploaded online on The Voicemall platform are expected to be created/recorded solely by the user after a
            proper thought process. No user will have any copyright on the samples he/she uploads on our platform. After
            creating an account at The Voicemall if a user is found not respecting these terms, conditions, and
            guidelines anyhow, then we at The Voicemall have all the legal rights to terminate his/her account without
            any prior notice.</p>

            <p>The Voicemall users shall confirm and declare solemnly, that after creating a Voicemall account, he/she will
            keep the client's scripts or any other content provided by the client, confidential and secure, during and
            after the execution of the client's project. All the users shall agree to the deduction of the artist/actor
            fee from their due amount, according to the policies of The Voicemall. The Voicemall has all the legal
            rights to change the artist/actor fee anytime we feel like it, without giving any prior notice to our users.</p>

            <h3> General Terms and Conditions</h3>
            <p>The Voicemall and its owner namely 3S Studio Pvt Ltd has all the legal rights to cancel the services and
            subscriptions of any user at any point of time. The users are expected to take note of the fact that any
            content of theirs that we possess or that transacts through our website or other resources might be
            transferred unencrypted. Your content might also be transmittepd over different networks. We at The
            Voicemall want to ensure our users that all the sensitive data (credit card/debit card, bank details) is
            transferred securely and in an encrypted form over different networks. No user is allowed to reproduce,
            sell, copy, share, or misuse any part of the content they transmit through The Voicemall's website without
            seeking explicit permission from The Voicemall team in written and signed form.</p>

            <p>We have several vendors who contribute diverse services through our websites. In case any service or product
            is unavailable at any moment of time, The Voicemall shall not be liable for its unavailability on our
            website.</p>

            <p>The Voicemall team has all the rights to remove or limit the availability of any of our products or service
            to any person or persons, or in any geographic or political division or jurisdiction. The discretion of such
            choices shall solely depend on our will. We have all the rights to change the descriptions or prices of any
            of the products or services we feature on our website. We also have the legal right to discontinue our
            services any time we feel like it.</p>

            <p>The Voicemall does not provide any warranty regarding the quality of our services, products, content, or
            information any user or customer purchases from us. In case any service, product, or other material does not
            meet the expectations of the buyer or user, we will not be liable to provide any refund or replacements. Our
            users are strictly expected to provide only that information about themselves that is accurate, updated, and
            matches the concerned legal documents.</p>

            <p>Under normal circumstances, the artist/actor shall provide the audio files in mp3/wav format. If any client
            wants the file in some other format, the concerned file format will be communicated clearly in the
            ''Instructions Section" of the hire page. Every artist/actor is expected to read these instructions from the
            client carefully. The Voicemall shall not be liable for any dispute over the instructions between the client
            and the artist/actor.</p>

            <h3> Terms related to accuracy, completeness, and updating of information</h3>
            <p>The Voicemall website contains lots of information. We at The Voicemall shall not be responsible for the
            accuracy, completeness, and updating of the information present on our website. We strictly advise our users
            to cross-check any information they find on our website in case of any high stake situations. The
            information is provided for the sole purpose of informing and not declaring.</p>

            <p>The Voicemall shall never be held responsible if someone misuses in any way, the services and products sold
            by us through our website or through any other transaction possible. All the users are again advised to keep
            updating themselves regarding these terms and services before making any transaction.</p>

            <h3> Terms related to the comments, feedback, and submissions made by the users</h3>
            <p>The Voicemall has all the legal rights to remove, hide or delete permanently, any content or submission or
            feedback provided by the users at our own will. It is our discretion that shall determine whether a content,
            submission, or feedback is obscene, offensive, abusive, defamatory, pornographic, or illegal. We shall have
            all the rights to not only remove such content but also report to the authorities if found necessary.</p>

            <p>The Voicemall expects its users not to disrespect the copyrights, trademarks, privacy policies, or other
            intellectual or proprietary rights of others including their clients, in their (user's) submission,
            feedback, content, etc. All the opinions, statements, feedback, and remarks made by the user will be his
            sole responsibility. Voicemail does not support any controversial remark made by any use whatsoever.</p>

            <h3> Voice Over Project Pricing</h3>
            <p>The price of any voice-over project visible on the website shall be applicable to a single script only. No
            user is allowed to add more than one script or language to a single script. If a user does not follow these
            guidelines, the entire project shall be cancelled. A single project can be created for a single script. For
            multiple scripts/languages, users shall create multiple voice-over projects.</p>

            <p>Every user shall select the purpose for every project. The Voicemall has all the rights to cancel any
            project if the misleading or wrong information is given by the user during project submission. The Voicemall
            is not liable to refund any canceled project.</p>

            <h3> Cancellation and Refund Policy for Clients</h3>
            <p>The Voicemall takes care of the swift completion of the voice-over project of a client by processing it as
            soon as an artist/ actor gets hired for it on the Voicemall. After the hiring process is completed the
            payment process gets initiated. After the completion of the payment process, no project can be cancelled or
            modified in any form.</p>

            <p>The Voicemall will credit back the complete payment made by the client if either the voice-over project gets
            expired or is rejected because the artist is unavailable. The refunds will come in the Voicemall wallet of
            the concerned client. Clients can choose to hire any other artist for the same project.</p>

            <p>In case of any technical problem faced by a client while selecting an artist, making payment, or any
            Voicemail wallet issues, he/she may contact our customer service team. The details of contacting our
            customer service are provided on the website only.</p>

            <h3>Website Usage and Content Promotion</h3>
            <p>
                The Voicemall strictly condemns and prohibits the usage of its website, content, services, or products
                for the following purposes: <br>
                ● Illegal usage <br>
                ● Unlawful acts <br>
                ● Promotion of unlawful activities <br>
                ● Violation of any law- state, federal, international or local <br>
                ● Infringement upon or violation of the intellectual property rights of anyone <br>
                ● Harassing, abusing, insulting, harming, defaming, intimidating, or discriminating against others on 
                the basis of caste, gender, nationality, creed, class, religion, language, ethnicity, race, etc. <br>
                ● For harming others (including us) through transmitting viruses, worms, malware intended to disrupt the
                services <br>
                ● Spamming and phishing <br>
                ● Spreading anything considered obscene, pornographic, immoral, etc. <br>

                <p>The Voicemall has all the legal rights to completely terminate the services of any user found guilty of
                the above-mentioned usage. We also have all the rights to report such users to the authorities to
                safeguard ourselves and others.</p>

                <p>The Voicemall does not guarantee the accuracy, security, or safety of any of our products or services.
                We have all the right to terminate any of our services or products without informing our users in
                advance. We shall never be held responsible for any damages that occurred because of such termination.</p>

        </div>
    </div>
    <!--eof: Contact Us-->
</div>