import { Component, ElementRef, OnInit, VERSION, ViewChild } from '@angular/core';
import { category, language, service } from '../modals/countries.modal';
import { UserService } from '../_services/user.service';
import { Country}  from 'country-state-city';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  content?: string;
  serviceList: any;
  languageList: any;
  categoryList: any;
  service!: service;
  language!: language;
  category!: category;

  servicefrom: any = {
    service_name: null,
  };

  
  languagefrom: any = {
    language: null,
    langCode:null
  };

  countryfrom: any = {
    country: null,
  };

  @ViewChild('country')
  country!: ElementRef;
  name = 'Angular ' + VERSION.major;
  countries = Country.getAllCountries();
  selectedCountry: any;

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    // this.userService.getPublicContent().subscribe(
    //   data => {
    //     this.content = data;
    //   },
    //   err => {
    //     this.content = JSON.parse(err.error).message;
    //   }
    // );
    this.getServiceList();
    this.getCategoryList();
    this.getLanguageList();
  }


  getServiceList() {
    this.userService.getServiceList().subscribe(data => {
      this.serviceList = data;
      if (this.serviceList !== null) {
          this.serviceList.forEach((element: {  service_name: string; }) => {
          //this.service.service_name === element.service_name;
        });
      }
    },
      err => {
        this.content = JSON.parse(err.error).message;
        console.log(this.content);
      }
    );
  }
  getLanguageList() {
    this.userService.getLanguageList().subscribe(data => {
      this.languageList = data;
      if (this.languageList !== null) {
          this.languageList.forEach((element: {  langCode: string; language: string; }) => {
          //this.language.langCode = element.langCode;
          //this.language.language = element.language;
        });
      }
    },
      err => {
        this.content = JSON.parse(err.error).message;
        console.log(this.content);
      }
    );
  }
  getCategoryList() {
    this.userService.getCategoryList().subscribe(data => {
      this.categoryList = data;
      if (this.categoryList !== null) {
          this.categoryList.forEach((element: {  category_name: string; }) => {
        });
      }
    },
      err => {
        this.content = JSON.parse(err.error).message;
        console.log(this.content);
      }
    );
  }

  onCountryChange($event: any): void {
    this.selectedCountry = JSON.parse(this.country.nativeElement.value);
    this.countryfrom.country = JSON.parse(this.country.nativeElement.value).name;  }
}
