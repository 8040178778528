import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../_services/user.service';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(private userService: UserService, private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {
  }

  isContact = true;
  isContactFailed = true;
  ContactErrorMessage = '';

  contactForm: any = {
    firct_name: null,
    last_name: null,
    email: null,
    contact: null,
    message: null
  };

  contactus(): void {
    debugger
    const { first_name, last_name, email, contact, message } = this.contactForm;

    this.userService.contact(first_name, last_name, email, contact, message).subscribe(
      data => {
        console.log(data);
        this.isContact = true;
        this.toastr.success('ThankYou Our admin will contact to you soon!','');
        this.reload();

      },
      err => {
        this.toastr.error('Error','');
        this.ContactErrorMessage = err.error.message;
        this.isContactFailed = false;
      }
    );
  }

  reload(){
    window.location.reload();
  }

 
}
