<!--getupdated newsleter-->
<form name="form" (ngSubmit)="f.form.valid" #f="ngForm" novalidate>
  <div class="getupdate">
    <div class="container">
      <div class="getintro">
        <div class="youinbox">
          <i></i>
          <strong>Get Updates & More<span>Thoughtful thoughts to your inbox</span></strong>
        </div>
        <div class="subscribe" *ngIf="isSubscribe">
          <input type="text" placeholder="Enter Email ID*" [(ngModel)]="subscribeForm.email" #email="ngModel"
            [ngModelOptions]="{standalone: true}" required email>
          <input type="submit" value="Submit" (click)="subscribe()">
          <div class="alert alert-danger" *ngIf="email.errors && f.submitted">
            <div *ngIf="email.errors.required">Email is required</div>
            <div *ngIf="email.errors.email">
              Email must be a valid email address
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<!--bof:Footer-->
<div id="footerbase">
  <div class="footerinside">
    <div class="boxes">
      <strong class="head">Information <i class="arrow"><span class="material-icons-outlined">
            chevron_right
          </span></i></strong>
      <ul class="content">
        <li><a (click)="about()">About Us</a></li>
        <li><a (click)="policy()">Policy</a></li>
        <li><a (click)="terms()">Terms</a></li>
        <!-- <li><a routerLink="/faq">FAQ's</a></li> -->
        <!-- <li><a href="#">Blog</a></li>
        <li><a href="#">Category</a></li>
        <li><a href="#">Services</a></li> -->
        <li><a (click)="howwework()">How We work</a></li>
        <li><a (click)="contact()">Contact us</a></li>
      </ul>
    </div>
    <div class="boxes">
      <strong class="head">Voice Talent <i class="arrow"><span class="material-icons-outlined">
            chevron_right
          </span></i></strong>
      <ul class="content">
        <!-- <li><a href="#">Voice Price</a></li> -->
        <li><a href="#">Register</a></li>
        <li><a href="#">Login</a></li>
        <!-- <li><a href="#">How it Works</a></li>
        <li><a href="#">Top 100 Artists</a></li> -->

      </ul>
    </div>
    <!-- <div class="boxes">
      <strong class="head">Voice Process <i class="arrow"><span class="material-icons-outlined">
            chevron_right
          </span></i></strong>
      <ul class="content">
        <li><a href="#">How it works</a></li>
        <li><a href="#">Help voice Artist</a></li>
        <li><a href="#">Success stories</a></li>

      </ul>
    </div> -->

    <div class="boxes">
      <strong class="head">Contact Info <i class="arrow"><span class="material-icons-outlined">
            chevron_right
          </span></i></strong>
      <div class="content">
        <div class="footersocial">
          <p class="footermap"> 3S Studio PVT LTD
            G-26, LGF, Kalkaji, New Delhi,
            Delhi 110019</p>
          <span class="footerno"><a href="#">+91 9811241103</a></span>
          <span class="footeremail"><a href="#">Info@thevoicemall.com</a></span>
          <ul>
            <li><a href="https://www.facebook.com/thevoicemall" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
            </li>
            <li><a href="#" ><i class="fa fa-google-plus" aria-hidden="true"></i></a></li>
            <li><a href="https://www.linkedin.com/in/the-voice-mall-0b4614244/"  target="_blank"><i class="fa fa-linkedin"
                  aria-hidden="true"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="copyright">&copy;2022 The Voice Mall</div>
</div>
<!--Eof:Footer-->