// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

// import "http://code.jquery.com/jquery-1.9.1.js";
// import  "https://code.jquery.com/ui/1.13.1/jquery-ui.js"
// import  "https://cdnjs.cloudflare.com/ajax/libs/chosen/1.8.7/chosen.jquery.min.js"
// import  "https://www.awayholidays.co.uk/promotions/atlantis-the-palm/js/jquery.poptrox.min.js"
// import  "https://www.landmarkmlp.com/js-plugin/owl.carousel/owl-carousel/owl.carousel.js"
// import  "http://www.newfriendsoptical.in/voicemall/js/dropdown.js"
