import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

//const API_URL = 'http://localhost:8080/api/test/';
const API_URL = 'http://wap.3sspl.com:8088/api/test/';
const Country_list = "country";
const Service_list = "services";
const Language_list = "language";
const Category_list = "categories";
const EDIT_PROFILE = "editProfile";
const EDIT_VOICE_PROFILE = "editVoiceProfile";
const Service_Offered_list = "serviceOffered";
const Education_list = "education";
const Recording_Setup_list = "recordingSetup";
const Voice_Role_list = "voiceRole";
const Accent_list = "accent";
const Style_list = "style";
const Voice_Age_list = "ageRange";
const PriceDeliveryTime = "priceDeliveryTime";
const TIME_AVAILABILITY = "availability";
const EQUIPMENT_EXPERIENCE = "equipmentExperience";
const CONTACT = "contact";
const SUBSCRIBE = "subscribe";
const VOICE_SAMPLE = "voiceSample";



const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient) { }

  // getPublicContent(): Observable<any> {
  //   return this.http.get(API_URL + 'all', { responseType: 'text' });
  // }

  getUserBoard(): Observable<any> {
    return this.http.get(API_URL + 'user', { responseType: 'text' });
  }

  // getModeratorBoard(): Observable<any> {
  //   return this.http.get(API_URL + 'mod', { responseType: 'text' });
  // }

  // getAdminBoard(): Observable<any> {
  //   return this.http.get(API_URL + 'admin', { responseType: 'text' });
  // }

  getCountryList(): Observable<any> {
    return this.http.get(API_URL + Country_list);
  }

  getServiceList(): Observable<any> {
    return this.http.get(API_URL + Service_list);
  }

  getLanguageList(): Observable<any> {
    return this.http.get(API_URL + Language_list);
  }

  getCategoryList(): Observable<any> {
    return this.http.get(API_URL + Category_list);
  }

  getRecordingSetupList(): Observable<any> {
    return this.http.get(API_URL + Recording_Setup_list);
  }

  getVoiceAgeList(): Observable<any> {
    return this.http.get(API_URL + Voice_Age_list);
  }

  getAccentList(): Observable<any> {
    return this.http.get(API_URL + Accent_list);
  }

  getVoiceRoleList(): Observable<any> {
    return this.http.get(API_URL + Voice_Role_list);
  }

  getStyleList(): Observable<any> {
    return this.http.get(API_URL + Style_list);
  }

  getServiceOfferedList(): Observable<any> {
    return this.http.get(API_URL + Service_Offered_list);
  }

  getEducationList(): Observable<any> {
    return this.http.get(API_URL + Education_list);
  }


  editProfile(editProfileForm:FormData): Observable<any> {
    return this.http.post(API_URL + EDIT_PROFILE, editProfileForm);
  }

  editVoiceProfile(language: string, category: string, recording_Setup: string, voice_age: string, 
    accents: string, role: string, style: string, userId: string): Observable<any> {
    return this.http.post(API_URL + EDIT_VOICE_PROFILE, {
      language, category, recording_Setup, voice_age, accents, role, style, userId
    }, httpOptions);
  }

  priceDeliveryTime(fields: any, userId: string): Observable<any> {
    return this.http.post(API_URL + PriceDeliveryTime, {fields, userId
    }, httpOptions);
  }

  availability(is24Available: boolean, daySunday: string, 
    dayMonday: string, dayTuesday: string, dayWednesday: string, dayThursday: string, dayFriday: string, daySaturday: string, 
    timeAvailability: boolean, startTime: string, endTime: string, userId: string): Observable<any> {
    return this.http.post(API_URL + TIME_AVAILABILITY, {
     is24Available, daySunday, dayMonday, dayTuesday, dayWednesday, dayThursday, dayFriday, daySaturday, timeAvailability,
      startTime,  endTime, userId
    }, httpOptions);
  }

  equipmentExperience(microphoneUsed: string, recordingEditing: string, socialEquipment: string, experience: string, 
    serviceOffered: string, clientWorkedFor: string, education: string, socialMediaLinkFB: string, socialMediaLinkTwitter: string, 
    socialMediaLinkLinked: string, userId: string): Observable<any> {
    return this.http.post(API_URL + EQUIPMENT_EXPERIENCE, {
      microphoneUsed, recordingEditing, socialEquipment, experience, serviceOffered, clientWorkedFor, education, socialMediaLinkFB, socialMediaLinkTwitter,
      socialMediaLinkLinked, userId
    }, httpOptions);
  }

  contact(first_name: string, last_name: string, email: string, contact: string, message: string): Observable<any> {
    return this.http.post(API_URL + CONTACT, {
      first_name, last_name, email, contact, message
    }, httpOptions);
  }

  subscribeUs(email: string): Observable<any> {
    return this.http.post(API_URL + SUBSCRIBE, {email
    }, httpOptions);
  }

  voiceSampleUpload(voiceSampleFromData:FormData): Observable<any> {
    return this.http.post(API_URL + VOICE_SAMPLE,voiceSampleFromData);
  }

}
