<!-- Banner -->

<div class="dashboardbanner" > 
    <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">   
    <div class="bannerheading"><h1>FAQ's</h1></div>
  </div>
  
  <div class="fullwidth  bg_purple">
    <!--bof: Contact Us-->
  
        <div class="w1200">
            <div class="aboutus">
                <h2>FAQ, Help Centre - Frequently Ask Questions - The Voice Mall</h2>
                <div class="getfront">
                <h3>I Am A Customer Looking For Voice Overs:</h3>
                 <div class="accordion">How can I hire an artist for my upcoming project?</div>
                 <div class="panel">
                 <p>To simplify and enhance productivity and efficiency for voice over services, we have launched Voyzapp where you can get voice over for your 
         projects instantly!<br>
         <br>
         
         The voice Mall is a technology powered platform where you can listen to voice samples of 1000s of artists, compare costs and delivery time and choose your preferred artist at the click of a button!</p>
                 </div>
                 
                 <div class="accordion">Can I cancel my membership plan after purchasing it?</div>
                 <div class="panel">
                 <p>To simplify and enhance productivity and efficiency for voice over services, we have launched Voyzapp where you can get voice over for your 
         projects instantly!<br>
         <br>
         
         The voice Mall is a technology powered platform where you can listen to voice samples of 1000s of artists, compare costs and delivery time and choose your preferred artist at the click of a button!</p>
                 </div>
                 
                 <div class="accordion">I see a promo code/discount already running at Voyzapp for all customers. Do I get additional discount </div>
                 <div class="panel">
                 <p>To simplify and enhance productivity and efficiency for voice over services, we have launched Voyzapp where you can get voice over for your 
         projects instantly!<br>
         <br>
         
         The voice Mall is a technology powered platform where you can listen to voice samples of 1000s of artists, compare costs and delivery time and choose your preferred artist at the click of a button!</p>
                 </div>
                 
                 <div class="accordion">How can I change my phone number?</div>
                 <div class="panel">
                 <p>To simplify and enhance productivity and efficiency for voice over services, we have launched Voyzapp where you can get voice over for your 
         projects instantly!<br>
         <br>
         
         The voice Mall is a technology powered platform where you can listen to voice samples of 1000s of artists, compare costs and delivery time and choose your preferred artist at the click of a button!</p>
                 </div>
                 
                 <div class="accordion">How is Voyzapp different from conventional service industry/studios?</div>
                 <div class="panel">
                 <p>To simplify and enhance productivity and efficiency for voice over services, we have launched Voyzapp where you can get voice over for your 
         projects instantly!<br>
         <br>
         
         The voice Mall is a technology powered platform where you can listen to voice samples of 1000s of artists, compare costs and delivery time and choose your preferred artist at the click of a button!</p>
                 </div>
                 
                 <div class="accordion">What all languages do you have voice over artists in?</div>
                 <div class="panel">
                 <p>To simplify and enhance productivity and efficiency for voice over services, we have launched Voyzapp where you can get voice over for your 
         projects instantly!<br>
         <br>
         
         The voice Mall is a technology powered platform where you can listen to voice samples of 1000s of artists, compare costs and delivery time and choose your preferred artist at the click of a button!</p>
                 </div>
         
                 <div class="accordion">We need a voice-over in a category which is not mentioned for a specific language. Can you assist us?</div>
                 <div class="panel">
                 <p>To simplify and enhance productivity and efficiency for voice over services, we have launched Voyzapp where you can get voice over for your 
         projects instantly!<br>
         <br>
         
         The voice Mall is a technology powered platform where you can listen to voice samples of 1000s of artists, compare costs and delivery time and choose your preferred artist at the click of a button!</p>
                 </div>
                 
                 <div class="accordion">We have a big e-learning project. Do you provide discounts for recurring big projects?</div>
                 <div class="panel">
                 <p>To simplify and enhance productivity and efficiency for voice over services, we have launched Voyzapp where you can get voice over for your 
         projects instantly!<br>
         <br>
         
         The voice Mall is a technology powered platform where you can listen to voice samples of 1000s of artists, compare costs and delivery time and choose your preferred artist at the click of a button!</p>
                 </div>
                 
                 <div class="accordion">Can I get a customized demo?</div>
                 <div class="panel">
                 <p>To simplify and enhance productivity and efficiency for voice over services, we have launched Voyzapp where you can get voice over for your 
         projects instantly!<br>
         <br>
         
         The voice Mall is a technology powered platform where you can listen to voice samples of 1000s of artists, compare costs and delivery time and choose your preferred artist at the click of a button!</p>
                 </div>
                 
                <div class="accordion">How Much Will My Voice Over Cost?</div>
                 <div class="panel">
                 <p>To simplify and enhance productivity and efficiency for voice over services, we have launched Voyzapp where you can get voice over for your 
         projects instantly!<br>
         <br>
         
         The voice Mall is a technology powered platform where you can listen to voice samples of 1000s of artists, compare costs and delivery time and choose your preferred artist at the click of a button!</p>
                 </div>
                 
                  <div class="accordion">Where is my script recorded?</div>
                 <div class="panel">
                 <p>To simplify and enhance productivity and efficiency for voice over services, we have launched Voyzapp where you can get voice over for your 
         projects instantly!<br>
         <br>
         
         The voice Mall is a technology powered platform where you can listen to voice samples of 1000s of artists, compare costs and delivery time and choose your preferred artist at the click of a button!</p>
                 </div>
         
         
         
         
         
                 
            </div>
            
                <div class="getfront">
                    <h3>I Am A Voice Actor:</h3>
                     <div class="accordion">Can you help me edit an Audio or Video?</div>
                     <div class="panel">
                     <p>To simplify and enhance productivity and efficiency for voice over services, we have launched Voyzapp where you can get voice over for your 
             projects instantly!<br>
             <br>
             
             The voice Mall is a technology powered platform where you can listen to voice samples of 1000s of artists, compare costs and delivery time and choose your preferred artist at the click of a button!</p>
                     </div>
                     
                     <div class="accordion">Why should I buy a customer membership plan?</div>
                     <div class="panel">
                     <p>To simplify and enhance productivity and efficiency for voice over services, we have launched Voyzapp where you can get voice over for your 
             projects instantly!<br>
             <br>
             
             The voice Mall is a technology powered platform where you can listen to voice samples of 1000s of artists, compare costs and delivery time and choose your preferred artist at the click of a button!</p>
                     </div>
                     
                     <div class="accordion">Do you also help in writing scripts for the voice overs?</div>
                     <div class="panel">
                     <p>To simplify and enhance productivity and efficiency for voice over services, we have launched Voyzapp where you can get voice over for your 
             projects instantly!<br>
             <br>
             
             The voice Mall is a technology powered platform where you can listen to voice samples of 1000s of artists, compare costs and delivery time and choose your preferred artist at the click of a button!</p>
                     </div>
                     
                     <div class="accordion">Can the voice artist provide a custom demo of my project?</div>
                     <div class="panel">
                     <p>To simplify and enhance productivity and efficiency for voice over services, we have launched Voyzapp where you can get voice over for your 
             projects instantly!<br>
             <br>
             
             The voice Mall is a technology powered platform where you can listen to voice samples of 1000s of artists, compare costs and delivery time and choose your preferred artist at the click of a button!</p>
                     </div>
                     
                     <div class="accordion">Can you provide any testimonials of your satisfied customers?</div>
                     <div class="panel">
                     <p>To simplify and enhance productivity and efficiency for voice over services, we have launched Voyzapp where you can get voice over for your 
             projects instantly!<br>
             <br>
             
             The voice Mall is a technology powered platform where you can listen to voice samples of 1000s of artists, compare costs and delivery time and choose your preferred artist at the click of a button!</p>
                     </div>
                     
                     <div class="accordion">Can you help me edit an Audio or Video?</div>
                     <div class="panel">
                     <p>To simplify and enhance productivity and efficiency for voice over services, we have launched Voyzapp where you can get voice over for your 
             projects instantly!<br>
             <br>
             
             The voice Mall is a technology powered platform where you can listen to voice samples of 1000s of artists, compare costs and delivery time and choose your preferred artist at the click of a button!</p>
                     </div>
             
                     <div class="accordion">Why should I buy a customer membership plan?</div>
                     <div class="panel">
                     <p>To simplify and enhance productivity and efficiency for voice over services, we have launched Voyzapp where you can get voice over for your 
             projects instantly!<br>
             <br>
             
             The voice Mall is a technology powered platform where you can listen to voice samples of 1000s of artists, compare costs and delivery time and choose your preferred artist at the click of a button!</p>
                     </div>
                     
                     <div class="accordion">Do you also help in writing scripts for the voice overs?</div>
                     <div class="panel">
                     <p>To simplify and enhance productivity and efficiency for voice over services, we have launched Voyzapp where you can get voice over for your 
             projects instantly!<br>
             <br>
             
             The voice Mall is a technology powered platform where you can listen to voice samples of 1000s of artists, compare costs and delivery time and choose your preferred artist at the click of a button!</p>
                     </div>
                     
                     <div class="accordion">Can the voice artist provide a custom demo of my project?</div>
                     <div class="panel">
                     <p>To simplify and enhance productivity and efficiency for voice over services, we have launched Voyzapp where you can get voice over for your 
             projects instantly!<br>
             <br>
             
             The voice Mall is a technology powered platform where you can listen to voice samples of 1000s of artists, compare costs and delivery time and choose your preferred artist at the click of a button!</p>
                     </div>
                     
                    <div class="accordion">Can you provide any testimonials of your satisfied customers?</div>
                     <div class="panel">
                     <p>To simplify and enhance productivity and efficiency for voice over services, we have launched Voyzapp where you can get voice over for your 
             projects instantly!<br>
             <br>
             
             The voice Mall is a technology powered platform where you can listen to voice samples of 1000s of artists, compare costs and delivery time and choose your preferred artist at the click of a button!</p>
                     </div>
                </div>
                <div class="requestfordemo"><a href="#">Request for free Demo</a></div>
            </div>
      </div>
      <!--eof: Contact Us-->
