 <!-- bof:wedgetleft -->
 <div class="wedgetleft">
    <div class="profsection">
      <div class="imgrow">
        <img src="/assets/images/icon.png" alt="profile">
        <strong>VM{{this.id}}98765</strong>
      </div>
      <ul>
        <li><a href="#"><i class="fa fa-envelope" aria-hidden="true"><span class="align">{{ currentUser.email}}</span></i></a></li>
        <li><a href="#"><i class="fa fa-phone" aria-hidden="true"></i> +91 {{this.contact}}</a></li>
      </ul>
    </div>
    <ul class="tabs">
      <li class="active"><a routerLink="/editprofile"><i class="artistperson"></i> <span>My Profile</span> </a></li>
      <!-- <li><a routerLink="/voicesample"><i class="transhistroy"></i><span>Edit Voice Sample</span></a></li> -->
        <!--  <li><a href="#tab3"><i class="wallet"></i> <span>My Wallet</span></a></li>
         <li><a href="#tab4"><i class="acountset"></i><span> Account Settings</span></a></li>
         <li><a href="#tab6"><i class="notification"></i><span> Notifications</span></a></li> -->
    </ul>
  </div>