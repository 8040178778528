<div class="dashboardbanner">
  <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
  <div class="bannerheading">
    <h1>Contact Us</h1>
  </div>
</div>

<div class="fullwidth  bg_purple">
  <!--bof: Contact Us-->
  <div class="w1200">
    <div class="contactus">
      <div class="contactleft">
        <h2>Contact Info</h2>
        <div class="c_detail">
          <div class="row">
            <i class="fa fa-map-marker" aria-hidden="true"></i>
            <div class="contact_detail">
              <strong>3S Studio PVT LTD</strong>
              <p> G-26, LGF, Kalkaji, New Delhi,<br>
                Delhi 110019</p>
            </div>
          </div>
          <div class="row">
            <i class="fa fa-envelope-open" aria-hidden="true"></i>
            <div class="contact_detail">
              <a href="#">Info@thevoicemall.com</a>
            </div>
          </div>
          <div class="row">
            <i class="fa fa-volume-control-phone" aria-hidden="true"></i>
            <div class="contact_detail">
              <a href="#">+91 9811241103</a>
            </div>
          </div>
          <div class="row">
            <iframe width="100%" height="200" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
              id="gmap_canvas"
              src="https://maps.google.com/maps?width=300&amp;height=200&amp;hl=en&amp;q=G-26,%20LGF,%20Kalkaji,%20New%20Delhi,%20Delhi%20110019%20Delhi+(3S%20Studio%20PVT%20LTD)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
            <a href='https://www.embedmap.net/'>add google map to website</a>
            <script type='text/javascript'
              src='https://embedmaps.com/google-maps-authorization/script.js?id=345011262a18439e620041ac8688ce2c1c9e203c'></script>
          </div>
        </div>
      </div>
      <div class="contactright">
        <strong>Send a message</strong>
        <form name="form" (ngSubmit)="f.form.valid" #f="ngForm" novalidate>
          <div class="formwrap" *ngIf="isContact">
            <div class="wrap">
              <div class="wrap-divide">
                <input type="text" placeholder="Enter First Name*" [(ngModel)]="contactForm.first_name"
                  [ngModelOptions]="{standalone: true}" #first_name="ngModel" [ngModelOptions]="{standalone: true}"
                  required minlength="3" maxlength="20">
                  <div class="alert alert-danger" *ngIf="first_name.errors && f.submitted">
                    <div *ngIf="first_name.errors.required">Username is required</div>
                    <div *ngIf="first_name.errors.minlength">
                      Username must be at least 3 characters
                    </div>
                    <div *ngIf="first_name.errors.maxlength">
                      Username must be at most 20 characters
                    </div>
                  </div>
              </div>
              
              <div class="wrap-divide">
                <input type="text" placeholder="Enter Last Name*" [(ngModel)]="contactForm.last_name"
                  [ngModelOptions]="{standalone: true}" required="">
                <span class="bottomline"></span>
              </div>
            </div>

            <div class="wrap">
              <div class="wrap-divide">
                <input type="text" placeholder="Enter Email Id*" [(ngModel)]="contactForm.email"
                  [ngModelOptions]="{standalone: true}" #email="ngModel" [ngModelOptions]="{standalone: true}" required
                  email>
                  <div class="alert alert-danger" *ngIf="email.errors && f.submitted">
                    <div *ngIf="email.errors.required">Email is required</div>
                    <div *ngIf="email.errors.email">
                      Email must be a valid email address
                    </div>
                  </div>
                <span class="bottomline"></span>
              </div>
             
              <div class="wrap-divide">
                <input type="text" placeholder="Enter Phone Number*" [(ngModel)]="contactForm.contact"
                  #contact="ngModel" [ngModelOptions]="{standalone: true}" minlength="8" maxlength="12" required>
                  <div class="alert alert-danger" *ngIf="contact.errors && f.submitted">
                    <div *ngIf="contact.errors.required">Contact number is required</div>
                  </div>
                  <span class="bottomline"></span>
              </div>
            </div>

            <div class="wrap">
              <textarea [(ngModel)]="contactForm.message"
                [ngModelOptions]="{standalone: true}">write your messages here..</textarea>
              <span class="bottomline"></span>
            </div>

            <div class="wrap">
              <input type="submit" value="Send" (click)="contactus()">
            </div>

          </div>
        </form>
      </div>
    </div>
  </div>
  <!--eof: Contact Us-->