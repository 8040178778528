import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../_services/user.service';
import { Router } from '@angular/router';
import { TokenStorageService } from '../_services/token-storage.service';
import { ElementRef, VERSION, ViewChild } from '@angular/core';
import { Country, State, City }  from 'country-state-city';
import { HttpClient } from '@angular/common/http';



@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  
  currentUser: any;
  contact: any;
  id: any;
  userId: any;

  //For Country and state and city basis on country

  @ViewChild('country')
  country!: ElementRef;
  @ViewChild('city')
  city!: ElementRef;
  @ViewChild('state')
  state!: ElementRef;
  name = 'Angular ' + VERSION.major;
  countries = Country.getAllCountries();
  states: any;
  cities: any;

  selectedCountry: any;
  selectedState: any;
  selectedCity: any;

   //for image upload
   uploadedImage: any;
   dbImage: any;
   postResponse: any;
   successResponse: any;
   image: any;
   
  

  constructor(private userService: UserService, private toastr: ToastrService, 
    private router: Router, private token: TokenStorageService, private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.currentUser = this.token.getUser();
    this.contact = this.token.getUser().contact;
    this.id = this.token.getUser().id;
  }

  editProfileForm: any = {
    email: null,
    dob: null,
    gender: null,
    address1: null,
    country: null,
    state: null,
    city: null,
    pinCode: null,
    timeZone: null,
    about: null
  
};

  isEdit = true;
  isEditFailed = true;
  EditErrorMessage = '';

  // editProfile(): void {
  //   const { email, dob, gender, address1, country, state, city, pinCode, timeZone, about} = this.editProfileForm;
  //   this.userService.editProfile(email, dob, gender, address1, country, state, city, pinCode, timeZone, about, this.userId = this.id).subscribe(
  //     data =>{
  //       this.isEdit = true;
  //       this.toastr.success('Details Update successfully','');
  //       this.router.navigateByUrl('/editvoice')
  //     },
  //     err => {
  //       this.toastr.error('Failed! Please fill all the details.','')
  //       this.EditErrorMessage = err.error.message;
  //       this.isEditFailed = true;
  //     }
  //   );
  // }


  editProfile(): void {
    debugger
    const fromData = new FormData();
    const editProfileForm: any = {
      email: this.editProfileForm.email,
      dob: this.editProfileForm.dob,
      gender: this.editProfileForm.gender,
      address1: this.editProfileForm.address1,
      country: this.editProfileForm.country,
      state: this.editProfileForm.state,
      city: this.editProfileForm.city,
      pinCode: this.editProfileForm.pinCode,
      timeZone: this.editProfileForm.timeZone,
      about: this.editProfileForm.about,
      name: this.image.name,
      userId: this.id

    };

    fromData.append("editProfileModal", JSON.stringify(editProfileForm));
    fromData.append("file", this.image, this.image.name);
    this.userService.editProfile(fromData).subscribe();
    if (this.editProfileForm !== null) {
      this.toastr.success('Thank you your profile has been update successfully', '');
      this.router.navigateByUrl('/editvoice')
    }
    else {
      this.toastr.error('Something went wrong', '');
    }
  }



  onCountryChange($event: any): void {
    this.states = State.getStatesOfCountry(JSON.parse(this.country.nativeElement.value).isoCode);
    this.selectedCountry = JSON.parse(this.country.nativeElement.value);
    this.editProfileForm.country = JSON.parse(this.country.nativeElement.value).name;
    this.cities = this.selectedState = this.selectedCity = null;
  }

  

  onStateChange($event: any): void {
    this.cities = City.getCitiesOfState(JSON.parse(this.country.nativeElement.value).isoCode, JSON.parse(this.state.nativeElement.value).isoCode)
    this.selectedState = JSON.parse(this.state.nativeElement.value);
    this.editProfileForm.state = JSON.parse(this.state.nativeElement.value).name;
    this.selectedCity = null;

  }

  onCityChange($event: any): void {
    this.selectedCity = JSON.parse(this.city.nativeElement.value)
    this.editProfileForm.city = JSON.parse(this.city.nativeElement.value).name;

  }

 
  public onImageUpload(event: any) {
    this.image = event.target.files[0];
  }

  // imageUploadAction() {
  //   debugger
  //   const imageFormData = new FormData();
  //   imageFormData.append('image', this.uploadedImage, this.uploadedImage.name);
  //   imageFormData.append('userId', this.userId = this.id);

  //   this.httpClient.post('http://localhost:8080/api/test/upload/image/', imageFormData, { observe: 'response' })
  //     .subscribe((response) => {
  //       if (response.status === 200) {
  //         this.postResponse = response;
  //         this.successResponse = this.postResponse.body.message;
  //       } else {
  //         this.successResponse = 'Image not uploaded due to some error!';
  //       }
  //     }
  //     );
  //   }
  
  // viewImage() {
  //   // this.httpClient.get('http://192.168.1.23/api/test/get/image/info/' + this.image)
  //   this.httpClient.get('http://localhost:8080/api/test/get/image/info/' + this.image)
  //     .subscribe(
  //       res => {
  //         this.postResponse = res;
  //         this.dbImage = 'data:image/jpeg;base64,' + this.postResponse.image;
  //       }
  //     );
  // }
}



