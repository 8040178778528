<!-- Banner -->
<div class="banner">
  <div id="owl-demo5">
    <div class="item">
      <img src="/assets/images/banner.jpg" alt="Banner">
    </div>
  </div>
  <div class="banner-inside">
    <div class="headingsection">
      <h1>Voice <span>Recording</span></h1>
    </div>
    <div class="searchvoice">
      <strong>Search Voice</strong>
      <div class="row">
        <div class="col servicecol">
          <select name="services" #services="ngModel" [(ngModel)]="servicefrom.service_name">
            <option [ngValue]="null" selected disabled>-- Select Service --</option>
            <option *ngFor="let data of serviceList" [label]="data.service_name" [value]="data.service_name"></option>
          </select>
        </div>
        <div class="col">
          <select name="language" #language="ngModel" [(ngModel)]="languagefrom.language">
            <option [ngValue]="null" selected disabled>-- Select Language --</option>
            <option *ngFor="let data of languageList" [label]="data.language" [value]="data.language"></option>
          </select>
        </div>
        <div class="col">
          <select #country name="country" id="country" (change)="onCountryChange($event)"
            (ngModel)="countryfrom.country" [ngModelOptions]="{standalone: true}">
            <option [value]="null">-- Select Your Country --</option>
            <option *ngFor="let country of countries" [value]="country | json">
              {{ country.name }} {{"("+country.phonecode+")"}}
            </option>
          </select>
        </div>
        <div class="col">
          <select>
            <option>-- Select Gender --</option>
            <option>Male</option>
            <option>Female</option>
          </select>
        </div>

        <div class="col searchicon">
          <input type="submit" value="Search">
        </div>

      </div>
    </div>
  </div>
</div>

<!--Clientcontainer-->
<div class="container">
  <!--bof:clientlist-->
  <div class="client">
    <div class="heading"><span>Our</span>
      <h2>Cleint List</h2>
    </div>
    <div class="clientlogo">
      <div class="row mx-auto my-auto justify-content-center">
        <div id="owl-demo2">
          <div class="item"><img src="/assets/images/clientlogo1.jpg" alt="ClientLogo"></div>
          <div class="item"><img src="/assets/images/clientlogo2.jpg" alt="ClientLogo"></div>
          <div class="item"><img src="/assets/images/clientlogo3.jpg" alt="ClientLogo"></div>
          <div class="item"><img src="/assets/images/clientlogo4.jpg" alt="ClientLogo"></div>
          <div class="item"><img src="/assets/images/clientlogo5.jpg" alt="ClientLogo"></div>
          <div class="item"><img src="/assets/images/clientlogo6.jpg" alt="ClientLogo"></div>
          <div class="item"><img src="/assets/images/clientlogo7.jpg" alt="ClientLogo"></div>
         
        </div>
      </div>
    </div>
  </div>
  <!--eof:clientlist-->

  <!--bof:testimonial-->
  <div class="testimonial">
    <div class="heading">
      <h2>Testimonial</h2>
    </div>
    <div class="clientlogo">
      <div class="row mx-auto my-auto justify-content-center">
        <div id="owl-demo3">
          <div class="item">
            <div class="monialcontent">
              <p>We are utilizing the services of  The Voice Mall for our audio recording, 
                translation and subtitling requirements in various Indian languages for our TTS & SRT Services over the last several years. 
                We found their services to be perfect, of very high quality and always on time. We trust and recommend 
                The Voice Mall for it’s services.</p>
              <h4>Professor Hema A. Murthy <span>IIT – Madras</span></h4>
            </div>
          </div>
          <div class="item">
            <div class="monialcontent">
              <p>We have always found the quality of production of the highest standard. Video and audio spots on Aadhar 
                produced by The Voice Mall for UIDAI were very well received by the client as well as target groups. 
                We would like to recommend the organization and wish them the best of luck.</p>
              <h4>Aashutosh Zunjarrao<span>Dentsu Aegis Network</span></h4>
            </div>
          </div>
          <div class="item">
            <div class="monialcontent">
              <p>We have been utilizing the services of The Voice Mall for more than last 2 decades. 
                The services offered by the offered by the organization has always been of highest quality & standard. 
                I would, therefore, recommend their name to anyone, who is desirous of getting quality audio product of any genre 
                for meeting their communication needs.</p>
              <h4>Vidya Raghavan <span>New concept information systems Pvt. Ltd.</span></h4>
            </div>
          </div>
          <div class="item">
            <div class="monialcontent">
              <p>I am pleased to affirm that we appreciate and highly value the prompt and quality audio services, 
                such as audio spots, in different languages provided by The Voice Mall as and when needed by us over the years.</p>
              <h4>Bhanu Pratap Joshi <span>FMC Corporation.</span></h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--eof:testimonial-->
</div>
<!--Clientcontainer-->


<!-- OurWork -->
<div class="ourwork">
  <!--Categories-->
  <div class="container">
    <div class="categories">
      <div class="heading">
        <h2>Voice Over Categories</h2>
      </div>
      <p>The Voice Mall provides voice over services in all these categories.</p>
      <div id="owl-demo4" class="row">
        <div class="item">
          <div class="col">
            <a href="#">
              <div class="iconimg"><i class="advertisment"></i></div>
              <h3>Documentary</h3>
            </a>
          </div>
        </div>
        <div class="item">
          <div class="col">
            <a href="#">
              <div class="iconimg"><i class="anouncment"></i></div>
              <h3>Anouncment</h3>
            </a>
          </div>
        </div>
        <div class="item">
          <div class="col">
            <a href="#">
              <div class="iconimg"><i class="child"></i></div>
              <h3>Child</h3>
            </a>
          </div>
        </div>
        <div class="item">
          <div class="col">
            <a href="#">
              <div class="iconimg"><i class="documentry"></i></div>
              <h3>Documentry</h3>
            </a>
          </div>
        </div>
        <div class="item">
          <div class="col">
            <a href="#">
              <div class="iconimg"><i class="explainer"></i></div>
              <h3>Explainer Video</h3>
            </a>
          </div>
        </div>
        <div class="item">
          <div class="col">
            <a href="#">
              <div class="iconimg"><i class="mimicry"></i></div>
              <h3>Mimicry</h3>
            </a>
          </div>
        </div>
        <div class="item">
          <div class="col">
            <a href="#">
              <div class="iconimg"><i class="radio"></i></div>
              <h3>Radio</h3>
            </a>
          </div>
        </div>
        <div class="item">
          <div class="col">
            <a href="#">
              <div class="iconimg"><i class="videogame"></i></div>
              <h3>Video Game</h3>
            </a>
          </div>
        </div>
        <div class="item">
          <div class="col">
            <a href="#">
              <div class="iconimg"><i class="advertisment"></i></div>
              <h3>Documentary</h3>
            </a>
          </div>
        </div>
        <div class="item">
          <div class="col">
            <a href="#">
              <div class="iconimg"><i class="anouncment"></i></div>
              <h3>Anouncment</h3>
            </a>
          </div>
        </div>
        <div class="item">
          <div class="col">
            <a href="#">
              <div class="iconimg"><i class="child"></i></div>
              <h3>Child</h3>
            </a>
          </div>
        </div>
        <div class="item">
          <div class="col">
            <a href="#">
              <div class="iconimg"><i class="documentry"></i></div>
              <h3>Documentry</h3>
            </a>
          </div>
        </div>
        <div class="item">
          <div class="col">
            <a href="#">
              <div class="iconimg"><i class="explainer"></i></div>
              <h3>Explainer Video</h3>
            </a>
          </div>
        </div>
        <div class="item">
          <div class="col">
            <a href="#">
              <div class="iconimg"><i class="mimicry"></i></div>
              <h3>Mimicry</h3>
            </a>
          </div>
        </div>
        <div class="item">
          <div class="col">
            <a href="#">
              <div class="iconimg"><i class="radio"></i></div>
              <h3>Radio</h3>
            </a>
          </div>
        </div>
        <div class="item">
          <div class="col">
            <a href="#">
              <div class="iconimg"><i class="videogame"></i></div>
              <h3>Video Game</h3>
            </a>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>


<!--Slider-->
<div class="container">
  <div class="slidercontainer">
    <div class="heading"><span>Our</span>
      <h2>Latest Voice</h2>
    </div>
    <div class="row mx-auto my-auto justify-content-center">
      <div id="owl-demo">
        <div class="item">
          <div class="box">
            <div class="imgbx">
              <a href="#">
                <img src="/assets/images/voice1.jpg" alt="Voicemall">
                <!-- <i class="fa fa-play-circle-o" aria-hidden="true" data-action="play"></i> -->
              </a>
              <a href="javascript:void(0);" data-action="play" data-method="play"><i class="fas fa-play"></i></a>
            </div>

            <div id="waveform">
              <!-- Here be the waveform -->
            </div>



            <div class="contentdetail">
              <div class="ratingpart">
                <h3>Demo</h3>
                <div class="star">
                  <span class="material-icons-outlined">star</span>
                  <span class="material-icons-outlined">star</span>
                  <span class="material-icons-outlined">star</span>
                  <span class="material-icons-outlined">star</span>
                  <span class="material-icons-outlined blackclr">star</span>
                </div>
                <strong>VS488518 <i></i></strong>
                <div class="gender">WOMEN</div>
                <div class="advert">Femail | Hindi | Advertise</div>
              </div>
              <div class="btngroup">
                <ul>
                  <li><a class="booknow" href="#">Book Now</a></li>
                  <li><a class="viewdetails" href="#">View Details</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="box">
            <div class="imgbx">
              <a href="#">
                <img src="/assets/images/voice2.jpg" alt="Voicemall">
                <i class="fa fa-play-circle-o" aria-hidden="true"></i>
              </a>

            </div>
            <div class="ui360"><a
                href="http://freshly-ground.com/data/audio/mpc/20090119%20-%20Untitled%20Groove.mp3"></a></div>
            <div class="contentdetail">
              <div class="ratingpart">
                <h3>Demo</h3>
                <div class="star">
                  <span class="material-icons-outlined">star</span>
                  <span class="material-icons-outlined">star</span>
                  <span class="material-icons-outlined">star</span>
                  <span class="material-icons-outlined">star</span>
                  <span class="material-icons-outlined blackclr">star</span>
                </div>
                <strong>VS488518 <i></i></strong>
                <div class="gender">WOMEN</div>
                <div class="advert">Femail | Hindi | Advertise</div>


              </div>
              <div class="btngroup">
                <ul>
                  <li><a class="booknow" href="#">Book Now</a></li>
                  <li><a class="viewdetails" href="#">View Details</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="box">
            <div class="imgbx">
              <a href="#">
                <img src="/assets/images/voice2.jpg" alt="Voicemall">
                <i class="fa fa-play-circle-o" aria-hidden="true"></i>
              </a>

            </div>
            <div class="ui360"><a
                href="http://freshly-ground.com/data/audio/mpc/20090119%20-%20Untitled%20Groove.mp3"></a></div>
            <div class="contentdetail">
              <div class="ratingpart">
                <h3>Demo</h3>
                <div class="star">
                  <span class="material-icons-outlined">star</span>
                  <span class="material-icons-outlined">star</span>
                  <span class="material-icons-outlined">star</span>
                  <span class="material-icons-outlined">star</span>
                  <span class="material-icons-outlined blackclr">star</span>
                </div>
                <strong>VS488518 <i></i></strong>
                <div class="gender">WOMEN</div>
                <div class="advert">Femail | Hindi | Advertise</div>


              </div>
              <div class="btngroup">
                <ul>
                  <li><a class="booknow" href="#">Book Now</a></li>
                  <li><a class="viewdetails" href="#">View Details</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="box">
            <div class="imgbx">
              <a href="#">
                <img src="/assets/images/1.jpg">
                <i class="fa fa-play-circle-o" aria-hidden="true"></i>
              </a>
            </div>
            <div class="contentdetail">
              <div class="ratingpart">
                <h3>Demo</h3>
                <div class="star">
                  <span class="material-icons-outlined">star</span>
                  <span class="material-icons-outlined">star</span>
                  <span class="material-icons-outlined">star</span>
                  <span class="material-icons-outlined">star</span>
                  <span class="material-icons-outlined blackclr">star</span>
                </div>
                <strong>VS488518 <i></i></strong>
                <div class="gender">WOMEN</div>
                <div class="advert">Femail | Hindi | Advertise</div>
              </div>
              <div class="btngroup">
                <ul>
                  <li><a class="booknow" href="#">Book Now</a></li>
                  <li><a class="viewdetails" href="#">View Details</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="box">
            <div class="imgbx">
              <a href="#">
                <img src="/assets/images/voice1.jpg" alt="Voicemall">
                <i class="fa fa-play-circle-o" aria-hidden="true"></i>
              </a>
            </div>
            <div class="contentdetail">
              <div class="ratingpart">
                <h3>Demo</h3>
                <div class="star">
                  <span class="material-icons-outlined">star</span>
                  <span class="material-icons-outlined">star</span>
                  <span class="material-icons-outlined">star</span>
                  <span class="material-icons-outlined">star</span>
                  <span class="material-icons-outlined blackclr">star</span>
                </div>
                <strong>VS488518 <i></i></strong>
                <div class="gender">WOMEN</div>
                <div class="advert">Femail | Hindi | Advertise</div>
              </div>
              <div class="btngroup">
                <ul>
                  <li><a class="booknow" href="#">Book Now</a></li>
                  <li><a class="viewdetails" href="#">View Details</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Slider-->

<div class="ourwork">
  <!--professionalartist-->
  <div class="artistbase">
    <div class="container">
      <div class="studiosection">
        <div class="heading">
          <h2>Services we offer</h2>
        </div>



        <div class="studio">
          <div class="studioimg"><img src="/assets/images/mike.jpg" alt="Mike"></div>
          <div class="studiointro">
            <div class="box">
              <i class="fa fa-star" aria-hidden="true"></i>
              <strong> Voice over </strong>
              <p>Voice-over is a production technique where an off-camera actor or person records dialogue for use in a film, 
                TV show, documentary, announcement, or commercial during the post-production process. 
                Productions use voice-over narration to provide additional context to the visuals or as a form of guided narration</p>
              <!-- <a href="#">Know more</a> -->
            </div>
            <div class="box">
              <i class="fa fa-star" aria-hidden="true"></i>
              <strong> Translation </strong>
              <p>Translation requires the individual to accurately convey the meaning of written words from one language to another.. 
                Translators predominantly work with business, technical, legal and scientific written materials. These include letters, 
                reports, articles and books. The Voice Mall provides Translation in  Indian & International languages</p>
              <!-- <a href="#">Know more</a> -->
            </div>
            <div class="box">
              <i class="fa fa-star" aria-hidden="true"></i>
              <strong>Video Production</strong>
              <p>Video production is simply everything that goes into the ideation, planning and execution of a video. 
                Historically video production involves three phases: Pre-production, Production and Post-production. 
                We at The Voice Mall provides  Dubbing, Subtitling, Transcription, Captioning, etc services as a part of Video production part.</p>
              <!-- <a href="#">Know more</a> -->
            </div>
            <div class="box">
              <i class="fa fa-star" aria-hidden="true"></i>
              <strong>Content writing </strong>
              <p>Content writing is the process of writing, editing, and publishing content in a digital format.That content can include blog posts, 
                video or podcast scripts, ebooks or whitepapers, press releases, product category descriptions, 
                landing page or social media copy and more.</p>
              <!-- <a href="#">Know more</a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>