<div class="dashboardbanner">
  <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
  <div class="bannerheading">Edit User Profile</div>
</div>

<div class="fullwidth bg_purple">
  <!--bof: myrecording-->
  <div class="w1200">
    <div class="pointgrid">
      <div class="pointcols">
        <span><i class="active" routerLink="/editprofile"></i></span>
        <strong>Personal Info</strong>
      </div>
      <div class="pointcols">
        <span><i routerLink="/editvoice"></i></span>
        <strong>Voice Profile</strong>
      </div>
      <div class="pointcols">
        <span><i routerLink="/pricedelivery"></i></span>
        <strong>Price, Delivery time &amp; Availability</strong>
      </div>
      <div class="pointcols">
        <span><i routerLink="/equipment"></i></span>
        <strong>Equipments &amp; Experience</strong>
      </div>
      <div class="pointcols">
        <span><i routerLink="/voicesample"></i></span>
        <strong>Voice &amp; Sample</strong>
       </div>   
    </div>
    <div class="wedget">
      <app-sidebar></app-sidebar>

      <!-- bof:wedgetright -->
      <div class="wedgetright">

        <div class="wedgetform">
          <!--bof:formsection-->
          <div class="form1">
            <h3 class="prof">Edit Profile</h3>
            <form class="formpanel" name="form" (ngSubmit)="f.form.valid" #f="ngForm" novalidate>
              <div class="formwrap" *ngIf="isEdit">
                <label>Email ID<span>*</span></label>
                <input type="text" placeholder="Enter your email address" 
                [(ngModel)]="editProfileForm.email" [ngModelOptions]="{standalone: true}" required  #email="ngModel" maxlength="25">
                  <div class="alert alert-danger" *ngIf="email.errors && f.submitted">
                    <div *ngIf="email.errors.required">Email Id is required..</div>
                  </div>
              </div>
              <div class="formwrap">
                <label>Date of Birth<span>*</span></label>
                <input type="date" placeholder="Enter your date of birth" [(ngModel)]="editProfileForm.dob"
                  [ngModelOptions]="{standalone: true}" required #dob="ngModel" maxlength="10">
                <div class="alert alert-danger" *ngIf="dob.errors && f.submitted">
                  <div *ngIf="dob.errors.required">Date of birth is required..</div>
                </div>
              </div>
              <div class="formwrap">
                <label>Select Gender<span>*</span></label>
                <select [(ngModel)]="editProfileForm.gender" [ngModelOptions]="{standalone: true}" required
                  #gender="ngModel">
                  <option [ngValue]="null" [disabled]="true">-- Select Gender --</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
                <div class="alert alert-danger" *ngIf="gender.errors && f.submitted">
                  <div *ngIf="gender.errors.required">Gender is required..</div>
                </div>
              </div>


              <!-- <div class="form-group">
               
                <input type="file" (change)="onImageUpload($event)" placeholder="Select image">
                <input type="button" (click)="imageUploadAction()" value="Upload Image" class="btn1">
                <output *ngIf=successResponse class="success"><b>{{successResponse}}</b></output>
              </div> -->
              

              <!-- <div class="formwrap custom-file-upload">
                <label>Profile Pic<span>*</span></label>
                 <p>Upload your latest professional pic. Should be upto 5MB - JPEG/PNG format</p> 
                <div class="profileinput">
                  <span class="profilepic">Browse</span>
                  <input type="file" (change)="onImageUpload($event)">
                </div>   
               </div> -->

               <div class="formwrap custom-file-upload">
                <label>Profile Pic<span>*</span></label>
                <p>Upload your latest professional pic. max upto 5 MB JPEG/PNG format</p>
                <div class="profileinput"><span class="profilepic">Browse</span>
                    <input id="" type="file" name="file" (change)="onImageUpload($event)">
                </div>
            </div>

<!-- 
              <div class="form-group">
                <input type="text" id="image" placeholder="Search Image" [(ngModel)]="image" name="image" />
                <input type="button" (click)="viewImage()" value="View Image" class="btn1">
              </div>

              <div *ngIf=dbImage>
                <img [src]="dbImage">
              </div> -->

              <div class="formwrap">
                <label>Address line 1</label>
                <input type="text" placeholder="Enter your address" 
                [(ngModel)]="editProfileForm.address1" [ngModelOptions]="{standalone: true}" maxlength="100">
              </div>

              <div class="formwrap">
                <label for="country">Country:</label><br />
                <select #country name="country" (change)="onCountryChange($event)" (ngModel)="editProfileForm.country"
                  [ngModelOptions]="{standalone: true}">
                  <option [value]="null">-- Select Your Country --</option>
                  <option *ngFor="let country of countries" [value]="country | json">
                    {{ country.name }} {{"("+country.phonecode+")"}}
                  </option>
                </select>
              </div>

              <div class="formwrap">
                <label for="state">State:</label><br />
                <select #state name="state" id="state" (change)="onStateChange($event)"
                  (ngModel)="editProfileForm.state" [ngModelOptions]="{standalone: true}">
                  <option [value]="null">-- Select Your State --</option>
                  <option *ngFor="let state of states" [value]="state | json">
                    {{ state.name }}
                  </option>
                </select>
              </div>

              <div class="formwrap">
                <label for="city">City:</label><br />
                <select #city name="city" id="city" (change)="onCityChange($event)" (ngModel)="editProfileForm.city"
                  [ngModelOptions]="{standalone: true}">
                  <option [value]="null">-- Select Your City --</option>
                  <option *ngFor="let city of cities" [value]="city | json">
                    {{ city.name }}
                  </option>
                </select>
              </div>

              <div class="formwrap">
                <label>Pincode/Zipcode</label>
                <input type="text" placeholder="Enter your pincode/zipcode" 
                [(ngModel)]="editProfileForm.pinCode" [ngModelOptions]="{standalone: true}" maxlength="10">
              </div>
              <div class="formwrap">
                <label>Timezone</label>
                <select [(ngModel)]="editProfileForm.timeZone" [ngModelOptions]="{standalone: true}" required
                  #timeZone="ngModel">
                  <option [ngValue]="null" [disabled]="true">-- Select Timezone --</option>
                  <option value="UTC-12:00">UTC-12:00</option>
                  <option value="UTC-11:00">UTC-11:00</option>
                  <option value="UTC-10:00">UTC-10:00</option>
                  <option value="UTC-09:30">UTC-09:30</option>
                  <option value="UTC-09:00">UTC-09:00</option>
                  <option value="UTC-08:00">UTC-08:00</option>
                  <option value="UTC-07:00">UTC-07:00</option>
                  <option value="UTC-06:00">UTC-06:00</option>
                  <option value="UTC-05:00">UTC-05:00</option>
                  <option value="UTC-04:00">UTC-04:00</option>
                  <option value="UTC-03:30">UTC-03:30</option>
                  <option value="UTC-03:00">UTC-03:00</option>
                  <option value="UTC-02:00">UTC-02:00</option>
                  <option value="UTC-01:00">UTC-01:00</option>
                  <option value="UTC 00:00">UTC 00:00</option>
                  <option value="UTC+01:00">UTC+01:00</option>
                  <option value="UTC+02:00">UTC+02:00</option>
                  <option value="UTC+03:00">UTC+03:00</option>
                  <option value="UTC+03:30">UTC+03:30</option>
                  <option value="UTC+04:00">UTC+04:00</option>
                  <option value="UTC+04:30">UTC+04:30</option>
                  <option value="UTC+05:00">UTC+05:00</option>
                  <option value="UTC+05:30">UTC+05:30</option>
                  <option value="UTC+05:45">UTC+05:45</option>
                  <option value="UTC+06:00">UTC+06:00</option>
                  <option value="UTC+06:30">UTC+06:30</option>
                  <option value="UTC+07:00">UTC+07:00</option>
                  <option value="UTC+08:00">UTC+08:00</option>
                  <option value="UTC+08:45">UTC+08:45</option>
                  <option value="UTC+09:00">UTC+09:00</option>
                  <option value="UTC+09:30">UTC+09:30</option>
                  <option value="UTC+10:00">UTC+10:00</option>
                  <option value="UTC+10:30">UTC+10:30</option>
                  <option value="UTC+11:00">UTC+11:00</option>
                  <option value="UTC+12:00">UTC+12:00</option>
                  <option value="UTC+12:45">UTC+12:45</option>
                  <option value="UTC+13:00">UTC+13:00</option>
                  <option value="UTC+14:00">UTC+14:00</option>
                </select>
              </div>
              <div class="formwrap">
                <label>About</label>
                <textarea placeholder="Please let us know. briefly about yourself.. (please do not mention contact details, etc)" 
                [(ngModel)]="editProfileForm.about" [ngModelOptions]="{standalone: true}" maxlength="500"></textarea>
              </div>

              <div class="formwrapfull">
                <div class="submitdiv">
                  <input type="submit" value="Save and Next" (click)="editProfile()">
                  <i class="fa fa-angle-right" aria-hidden="true"></i>
                </div>
                <strong>Items marked with <span>*</span> are mendatory</strong>
              </div>

            </form>
          </div>
          <!--eof:formsection-->

        </div>
      </div>
    </div>