import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { BoardAdminComponent } from './board-admin/board-admin.component';
import { BoardModeratorComponent } from './board-moderator/board-moderator.component';
import { BoardUserComponent } from './board-user/board-user.component';
import { authInterceptorProviders } from './_helpers/auth.interceptor';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { FaqComponent } from './faq/faq.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { EditVoiceProfileComponent } from './edit-voice-profile/edit-voice-profile.component';
import { PriceDeliveryTimeComponent } from './price-delivery-time/price-delivery-time.component';
import { EquipmentExperienceComponent } from './equipment-experience/equipment-experience.component';
import { ThankYouMessageComponent } from './thank-you-message/thank-you-message.component';
import { TermsComponent } from './terms/terms.component';
import { PolicyComponent } from './policy/policy.component';
import { HowWeWorkComponent } from './how-we-work/how-we-work.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { VoiceSampleComponent } from './voice-sample/voice-sample.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    ProfileComponent,
    BoardAdminComponent,
    BoardModeratorComponent,
    BoardUserComponent,
    HeaderComponent,
    FooterComponent,
    AboutComponent,
    ContactComponent,
    FaqComponent,
    EditProfileComponent,
    EditVoiceProfileComponent,
    PriceDeliveryTimeComponent,
    EquipmentExperienceComponent,
    ThankYouMessageComponent,
    TermsComponent,
    PolicyComponent,
    HowWeWorkComponent,
    DisclaimerComponent,
    SidebarComponent,
    VoiceSampleComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),    
  ],
  providers: [authInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
