import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../_services/user.service';
import { Router } from '@angular/router';
import { TokenStorageService } from '../_services/token-storage.service';


@Component({
  selector: 'app-equipment-experience',
  templateUrl: './equipment-experience.component.html',
  styleUrls: ['./equipment-experience.component.css']
})
export class EquipmentExperienceComponent implements OnInit {
  serviceOfferedList: any;
  content: any;
  educationList: any;

  //Fetch User log in details
  currentUser: any;
  contact: any;
  id: any;
  userId: any;

  constructor(private userService: UserService, private toastr: ToastrService, private router: Router, private token: TokenStorageService,) { }

  ngOnInit(): void {
    this.getServiceOfferedList();
    this.getEducationList();

    this.currentUser = this.token.getUser();
    this.contact = this.token.getUser().contact;
    this.id = this.token.getUser().id;
  }

  equipmentExperienceForm: any = {
    microphoneUsed: null,
    recordingEditing: null,
    socialEquipment: null,
    experience: null,
    serviceOffered: null,
    clientWorkedFor: null,
    education: null,
    socialMediaLinkFB: null,
    socialMediaLinkTwitter: null,
    socialMediaLinkLinked: null
  };

  equipmentExperience(): void {

    const {microphoneUsed, recordingEditing, socialEquipment, experience, serviceOffered, clientWorkedFor, education, socialMediaLinkFB, socialMediaLinkTwitter,
      socialMediaLinkLinked} = this.equipmentExperienceForm;

    this.userService.equipmentExperience(  microphoneUsed, recordingEditing, socialEquipment, experience, serviceOffered, clientWorkedFor, education, socialMediaLinkFB, socialMediaLinkTwitter,
      socialMediaLinkLinked, this.userId = this.id).subscribe();
      if(this.equipmentExperienceForm !==null){
        this.toastr.success('Thank you your profile has been update successfully','');
        this.router.navigateByUrl('/voicesample');
      }
      else{
        this.toastr.error('Error','');
      }
  }

  getServiceOfferedList() {
    this.userService.getServiceOfferedList().subscribe(data => {
      this.serviceOfferedList = data;
      if (this.serviceOfferedList !== null) {
        this.serviceOfferedList.forEach((element: { service_offered: string; }) => {
          // this.Countries.country === element.country;
          // this.Countries.currencyCode = element.currencyCode;
          // this.Countries.iso = element.iso;
        });
      }
    },
      err => {
        this.content = JSON.parse(err.error).message;
        console.log(this.content);
      }
    );
  }

  getEducationList() {
    this.userService.getEducationList().subscribe(data => {
      this.educationList = data;
      if (this.educationList !== null) {
        this.educationList.forEach((element: { education: string; }) => {
          // this.Countries.country === element.country;
          // this.Countries.currencyCode = element.currencyCode;
          // this.Countries.iso = element.iso;
        });
      }
    },
      err => {
        this.content = JSON.parse(err.error).message;
        console.log(this.content);
      }
    );
  }

  thankyou(){
    this.router.navigateByUrl('/thankyou');
  }
  
}
