<!-- Banner -->

<div class="dashboardbanner">
    <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
    <div class="bannerheading">Equipments & Experience</div>
</div>

<div class="fullwidth bg_purple">
    <!--bof: myrecording-->
    <div class="w1200">
        <div class="pointgrid">
            <div class="pointcols">
                <span><i class="active" routerLink="/editprofile"></i></span>
                <strong>Personal Info</strong>
            </div>
            <div class="pointcols">
                <span><i class="active" routerLink="/editvoice"></i></span>
                <strong>Voice Profile</strong>
            </div>
            <div class="pointcols">
                <span><i class="active" routerLink="/pricedelivery"></i></span>
                <strong>Price, Delivery time &amp; Availability</strong>
            </div>
            <div class="pointcols">
                <span><i class="active"></i></span>
                <strong>Equipments &amp; Experience</strong>
            </div>
            <div class="pointcols">
                <span><i routerLink="/voicesample"></i></span>
                <strong>Voice &amp; Sample</strong>
               </div>   
        </div>
        <div class="wedget">
            <!-- bof:wedgetleft -->
            <app-sidebar></app-sidebar>
            <!-- bof:wedgetright -->
            <div class="wedgetright">

                <div class="wedgetform">

                    <h3>Equipments &amp; Experience</h3>
                    <!--bof:formsection-->
                    <div class="form1">
                        <form class="formpanel">
                            <div class="formwrap">
                                <label>Microphone used</label>
                                <p>If you are currently using one, mention it.</p>
                                <input type="text" [(ngModel)]="equipmentExperienceForm.microphoneUsed"
                                    [ngModelOptions]="{standalone: true}" maxlength="100">
                            </div>
                            <div class="formwrap">
                                <label>Recording/Editing Software</label>
                                <p style="margin-bottom: 18px;">Do you edit/record your own material? What software do
                                    you employ?</p>
                                <input type="text" [(ngModel)]="equipmentExperienceForm.recordingEditing"
                                    [ngModelOptions]="{standalone: true}" maxlength="100">
                            </div>
                            <div class="formwrap">
                                <label>Special Equipment</label>
                                <p>Do you have any specialised equipment?</p>
                                <input type="text" [(ngModel)]="equipmentExperienceForm.socialEquipment"
                                    [ngModelOptions]="{standalone: true}" maxlength="100">
                            </div>
                            <div class="formwrap">
                                <label>Experience</label>
                                <p>How long have you been making recordings?</p>
                                <input type="text" [(ngModel)]="equipmentExperienceForm.experience"
                                    [ngModelOptions]="{standalone: true}" maxlength="100">
                            </div>
                            <div class="formwrap">
                                <label>Services Offered </label>
                                <p>What services exactly do you provide? You may select more than one.</p>
                                <select [(ngModel)]="equipmentExperienceForm.serviceOffered"
                                    [ngModelOptions]="{standalone: true}" multiple>
                                    <option [ngValue]="null" selected disabled>Select Services Offered</option>
                                    <option *ngFor="let data of serviceOfferedList" [label]="data.serviceOffered"
                                        [value]="data.serviceOffered"></option>
                                </select>
                            </div>
                            <div class="formwrap">
                                <label>Clients worked for </label>
                                <p>We'd like to know who all of your clients are to whom you've given your voice. </p>
                                <input type="text" [(ngModel)]="equipmentExperienceForm.clientWorkedFor"
                                    [ngModelOptions]="{standalone: true}" maxlength="100">
                            </div>

                            <div class="formwrap">
                                <label>Education </label>
                                <p>We understand that your artistic expression is all that matters!</p>
                                <select [(ngModel)]="equipmentExperienceForm.education"
                                    [ngModelOptions]="{standalone: true}">
                                    <option [ngValue]="null" selected disabled>Select Education</option>
                                    <option *ngFor="let data of educationList" [label]="data.education"
                                        [value]="data.education"></option>
                                </select>
                            </div>

                            <div class="formwrap">
                                <label>Social Media Profile link (FaceBook)</label>
                                <input type="text" [(ngModel)]="equipmentExperienceForm.socialMediaLinkFB"
                                    [ngModelOptions]="{standalone: true}" maxlength="50">
                            </div>

                            <div class="formwrap">
                                <label>Social Media Profile link (Twitter)</label>
                                <input type="text" [(ngModel)]="equipmentExperienceForm.socialMediaLinkTwitter"
                                    [ngModelOptions]="{standalone: true}" maxlength="50">
                            </div>

                            <div class="formwrap">
                                <label>Social Media Profile link (LinkedIn)</label>
                                <input type="text" [(ngModel)]="equipmentExperienceForm.socialMediaLinkLinked"
                                    [ngModelOptions]="{standalone: true}" maxlength="50">
                            </div>

                            <div class="formwrapfull">

                                <div class="submitdiv">
                                    <input type="submit" value="Submit" (click)="equipmentExperience()">
                                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                                </div>
                                <strong style="top: 0;">Items marked with <span>*</span> are mendatory</strong>
                            </div>
                        </form>
                        <!--eof:formsection-->

                    </div>
                    <!--eof: form-->
                </div>
            </div>
        </div>
        <!--eof: myrecording-->