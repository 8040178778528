import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from '../_services/token-storage.service';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-voice-sample',
  templateUrl: './voice-sample.component.html',
  styleUrls: ['./voice-sample.component.css']
})
export class VoiceSampleComponent implements OnInit {
  //Fetch User log in details
  currentUser: any;
  contact: any;
  id: any;
  userId: any;
  languageList: any;
  categoriesList: any;
  accentList: any;
  roleList: any;
  styleList: any;
  voiceList: any;
  content: any;
  image!: File;
  ifChecked: Boolean = false;

  //Open Modal for terms & conditions
  @ViewChild("outsideElement", { static: true })
  outsideElement!: ElementRef;
  @ViewChild('modalView', { static: true })
  modalView$!: ElementRef;



  constructor(private userService: UserService, private toastr: ToastrService, private token: TokenStorageService, private router: Router) { }

  ngOnInit(): void {
    this.currentUser = this.token.getUser();
    this.contact = this.token.getUser().contact;
    this.id = this.token.getUser().id;
    this.getLanguageList();
    this.getCategoryList();
    this.getAccentList();
    this.getVoiceRoleList();
    this.getStyleList();

  }

  voiceSampleFrom: any = {
    sampleVoice: null,
    language: null,
    category_name: null,
    accent: null,
    role_name: null,
    style_name: null,
    name: null
  }

  public onImageUpload(event: any) {
    this.image = event.target.files[0];
  }

  voiceSampleUpload(): void {
    const fromData = new FormData();
    const voiceSampleFromData: any = {
      sampleVoice: this.voiceSampleFrom.sampleVoice,
      language: this.voiceSampleFrom.language,
      category_name: this.voiceSampleFrom.category_name,
      accent: this.voiceSampleFrom.accent,
      role_name: this.voiceSampleFrom.role_name,
      style_name: this.voiceSampleFrom.style_name,
      name: this.image.name,
      userId: this.id

    };

    fromData.append("voiceSampleModal", JSON.stringify(voiceSampleFromData));
    fromData.append("file", this.image, this.image.name);
    this.userService.voiceSampleUpload(fromData).subscribe();
    if (this.ifChecked == true && this.voiceSampleFrom !== null) {
      this.toastr.success('Thank you your profile has been update successfully', '');
      this.router.navigateByUrl('/thankyou');
    }
    else {
      this.toastr.error('You must have to agree with terms and condition', '');
    }
  }



  getLanguageList() {
    this.userService.getLanguageList().subscribe(data => {
      this.languageList = data;
      if (this.languageList !== null) {
        this.languageList.forEach((element: { language: string; }) => {
        });
      }
    },
      err => {
        this.content = JSON.parse(err.error).message;
        console.log(this.content);
      }
    );
  }

  getCategoryList() {
    this.userService.getCategoryList().subscribe(data => {
      this.categoriesList = data;
      if (this.categoriesList !== null) {
        this.categoriesList.forEach((element: { category_name: string; }) => {
        });
      }
    },
      err => {
        this.content = JSON.parse(err.error).message;
        console.log(this.content);
      }
    );
  }

  getVoiceRoleList() {
    this.userService.getVoiceRoleList().subscribe(data => {
      this.roleList = data;
      if (this.roleList !== null) {
        this.roleList.forEach((element: { role_name: string; }) => {
        });
      }
    },
      err => {
        this.content = JSON.parse(err.error).message;
        console.log(this.content);
      }
    );
  }

  getAccentList() {
    this.userService.getAccentList().subscribe(data => {
      this.accentList = data;
      if (this.accentList !== null) {
        this.accentList.forEach((element: { accent: string; }) => {
        });
      }
    },
      err => {
        this.content = JSON.parse(err.error).message;
        console.log(this.content);
      }
    );
  }

  getStyleList() {
    this.userService.getStyleList().subscribe(data => {
      this.styleList = data;
      if (this.styleList !== null) {
        this.styleList.forEach((element: { style_name: string; }) => {
        });
      }
    },
      err => {
        this.content = JSON.parse(err.error).message;
        console.log(this.content);
      }
    );
  }


  openModal() {
    this.modalView$.nativeElement.classList.add('visible');
  }

  closeModal() {
    this.modalView$.nativeElement.classList.remove('visible');
  }



  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: any) {
    const outsideElement = this.outsideElement.nativeElement.contains(targetElement);
    if (outsideElement) {
      this.modalView$.nativeElement.classList.remove('visible');
    }
  }

}
