<!--bof: myrecording-->
<div class="w1200">
   <div class="wedget">
      <!-- bof:wedgetleft -->
      <div class="wedgetleft">
      <app-sidebar></app-sidebar>
      </div>

      <!-- bof:wedgetright -->
      <div class="wedgetright">
         <div class="wedgetform">
            <!--bof:formsection-->
            <div class="form1">
               <img src="/assets/images/thankyou.jpg">
            </div>
            <!--eof:formsection-->

         </div>
      </div>
   </div>