import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../_services/user.service';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TokenStorageService } from '../_services/token-storage.service';


@Component({
  selector: 'app-price-delivery-time',
  templateUrl: './price-delivery-time.component.html',
  styleUrls: ['./price-delivery-time.component.css']
})
export class PriceDeliveryTimeComponent implements OnInit {
  name: any;
  fields: any;
//Fetch User log in details
currentUser: any;
contact: any;
id: any;
userId: any;



  userForm = new FormGroup({
  });


  Data: any = {
    value1: null,
    value2: null,
    value77: null,
    value78: null,
    value3: null,
    value4: null,
    value5: null,
    value6: null,
    value7: null,
    value8: null,
    value9: null,
    value10: null,
    value11: null,
    value12: null,
    value13: null,
    value14: null,
    value15: null,
    value16: null,
    value17: null,
    value18: null,
    value19: null,
    value20: null,
    value21: null,
    value22: null,
    value23: null,
    value24: null,
    value25: null,
    value26: null,
    value27: null,
    value28: null,
    value29: null,
    value30: null,
    value31: null,
    value32: null,
    value33: null,
    value34: null,
    value35: null,
    value36: null,
    value37: null,
    value38: null,
    value39: null,
    value40: null,
    value41: null,
    value42: null,
    value43: null,
    value44: null,
    value45: null,
    value46: null,
    value47: null,
    value48: null,
    value49: null,
    value50: null,
    value51: null,
    value52: null,
    value53: null,
    value54: null,
    value55: null,
    value56: null,
    value57: null,
    value58: null,
    value59: null,
    value60: null,
    value61: null,
    value62: null,
    value63: null,
    value64: null,
    value65: null,
    value66: null,
    value67: null,
    value68: null,
    value69: null,
    value70: null,
    value71: null,
    value72: null,
    value73: null,
    value74: null,
    value75: null,
    value76: null
  }
  

  constructor(private userService: UserService, private toastr: ToastrService, private router: Router,private fb: FormBuilder, private token: TokenStorageService) { }

  ngOnInit() {
    this.currentUser = this.token.getUser();
    this.contact = this.token.getUser().contact;
    this.id = this.token.getUser().id;
  }

  data(){
    this.fields = {
      userId: this.id,
        price_data: [
          {
            id: 'price_1',
            value: this.Data.value1
          },
          {
            id: 'price_2',
            value: this.Data.value2
          },
          {
            id: 'price_77',
            value: this.Data.value77
          },
          {
            id: 'price_78',
            value: this.Data.value78
          },
          {
            id: 'price_3',
            value: this.Data.value3
          }, 
          {
            id: 'price_4',
            value: this.Data.value4
          },
          {
            id: 'price_5',
            value: this.Data.value5
          },
          {
            id: 'price_6',
            value: this.Data.value6
          },
          {
            id: 'price_7',
            value: this.Data.value7
          },
          {
            id: 'price_8',
            value: this.Data.value8
          },
          {
            id: 'price_9',
            value: this.Data.value9
          },
          {
            id: 'price_10',
            value: this.Data.value10
          },
          {
            id: 'price_11',
            value: this.Data.value11
          },
          {
            id: 'price_12',
            value: this.Data.value12
          },
          {
            id: 'price_13',
            value: this.Data.value13
          },
          {
            id: 'price_14',
            value: this.Data.value14
          },
          {
            id: 'price_15',
            value: this.Data.value15
          },
          {
            id: 'price_16',
            value: this.Data.value16
          },
          {
            id: 'price_17',
            value: this.Data.value17
          },
          {
            id: 'price_18',
            value: this.Data.value18
          },
          {
            id: 'price_19',
            value: this.Data.value19
          },
          {
            id: 'price_20',
            value: this.Data.value20
          },
          {
            id: 'price_21',
            value: this.Data.value21
          }, 
          {
            id: 'price_22',
            value: this.Data.value22
          },
          {
            id: 'price_23',
            value: this.Data.value23
          },
          {
            id: 'price_24',
            value: this.Data.value24
          },
          {
            id: 'price_25',
            value: this.Data.value25
          },     
          {
            id: 'price_26',
            value: this.Data.value26
          },   
          {
            id: 'price_27',
            value: this.Data.value27
          },   
          {
            id: 'price_28',
            value: this.Data.value28
          },   
          {
            id: 'price_29',
            value: this.Data.value29
          },   
          {
            id: 'price_30',
            value: this.Data.value30
          },   
          {
            id: 'price_31',
            value: this.Data.value31
          },   
          {
            id: 'price_32',
            value: this.Data.value32
          },   
          {
            id: 'price_33',
            value: this.Data.value33
          },   
          {
            id: 'price_34',
            value: this.Data.value34
          },   
          {
            id: 'price_35',
            value: this.Data.value35
          },   
          {
            id: 'price_36',
            value: this.Data.value36
          },   
          {
            id: 'price_37',
            value: this.Data.value37
          },     
          {
            id: 'price_38',
            value: this.Data.value38
          },   
          {
            id: 'price_39',
            value: this.Data.value39
          },   
          {
            id: 'price_40',
            value: this.Data.value40
          },   
          {
            id: 'price_41',
            value: this.Data.value41
          },   
          {
            id: 'price_42',
            value: this.Data.value42
          },   
          {
            id: 'price_43',
            value: this.Data.value43
          },   
          {
            id: 'price_44',
            value: this.Data.value44
          },   
          {
            id: 'price_45',
            value: this.Data.value45
          },   
          {
            id: 'price_46',
            value: this.Data.value46
          },   
          {
            id: 'price_47',
            value: this.Data.value47
          },   
          {
            id: 'price_48',
            value: this.Data.value48
          },   
          {
            id: 'price_49',
            value: this.Data.value49
          },   
          {
            id: 'price_50',
            value: this.Data.value50
          },    {
            id: 'price_51',
            value: this.Data.value51
          },   
          {
            id: 'price_52',
            value: this.Data.value52
          },   
          {
            id: 'price_53',
            value: this.Data.value53
          },   
          {
            id: 'price_54',
            value: this.Data.value54
          },   
          {
            id: 'price_55',
            value: this.Data.value55
          },   
          {
            id: 'price_56',
            value: this.Data.value56
          },   
          {
            id: 'price_57',
            value: this.Data.value57
          },   
          {
            id: 'price_58',
            value: this.Data.value58
          },   
          {
            id: 'price_59',
            value: this.Data.value59
          },   
          {
            id: 'price_60',
            value: this.Data.value60
          },   
          {
            id: 'price_61',
            value: this.Data.value61
          },   
          {
            id: 'price_62',
            value: this.Data.value62
          },   
          {
            id: 'price_63',
            value: this.Data.value63
          },   
          {
            id: 'price_64',
            value: this.Data.value64
          },   
          {
            id: 'price_65',
            value: this.Data.value65
          },   
          {
            id: 'price_66',
            value: this.Data.value66
          },   
          {
            id: 'price_67',
            value: this.Data.value67
          },   
          {
            id: 'price_68',
            value: this.Data.value68
          },   
          {
            id: 'price_69',
            value: this.Data.value69
          },   
          {
            id: 'price_70',
            value: this.Data.value70
          },   
          {
            id: 'price_71',
            value: this.Data.value71
          },   
          {
            id: 'price_72',
            value: this.Data.value72
          },   
          {
            id: 'price_73',
            value: this.Data.value73
          },   
          {
            id: 'price_74',
            value: this.Data.value74
          },   
          {
            id: 'price_75',
            value: this.Data.value75
          },   
          {
            id: 'price_76',
            value: this.Data.value76
          }  

        ]
      
    };
    //console.log("uiyiunoi"+ this.fields);
    this.userForm = this.fb.group({
        price_data: this.fb.array([])
    });
    this.patch()
    this.priceDeliveryTime()
  }
  submit(value: any) {
    console.log(value);
  }

  patch() {
    const control = <FormArray>this.userForm.get('price_data');
    this.fields.price_data.forEach((x: any) => {
      control.push(this.patchValues(x.id, x.value))
    })
  }

  patchValues(id: any, value: any) {
    return this.fb.group({
      id: [id],
      value: [value]
    })
  }

  priceDeliveryTimeForm: any = {
    value: null,
    status: null
  };

  priceDeliveryTime(): void {
    this.userService.priceDeliveryTime(this.fields, this.userId = this.id).subscribe();
    if(this.priceDeliveryTimeForm !==null && this.availabilityForm !=null){
      this.availability();
      this.router.navigateByUrl('/equipment');
    }
    else{
      this.toastr.error('Error','');
    }
  }


  availabilityForm: any = {
    is24Available: null,
    //weeklyOff: null,
    daySunday: null,
    dayMonday: null,
    dayTuesday: null,
    dayWednesday: null,
    dayThursday: null,
    dayFriday: null,
    daySaturday: null,
    timeAvailability: null,
    startTime: null,
    endTime: null


  };

  availability(): void {
    const {is24Available, daySunday, dayMonday, dayTuesday, dayWednesday, dayThursday, 
      dayFriday, daySaturday, timeAvailability,
      startTime,  endTime} = this.availabilityForm;

    this.userService.availability(is24Available, daySunday, dayMonday, dayTuesday, dayWednesday, dayThursday, 
      dayFriday, daySaturday, timeAvailability,
      startTime,  endTime, this.userId).subscribe(
       data =>{
        this.toastr.success('Price, Delivery time & Availability Details Update successfully','');
      },
      err => {
        this.toastr.error('Error','');
      }
    );
  }


}
