import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../_services/user.service';
import { Router } from '@angular/router';
import { TokenStorageService } from '../_services/token-storage.service';

//declare var jQuery: any;
declare var $: any;


@Component({
  selector: 'app-edit-voice-profile',
  templateUrl: './edit-voice-profile.component.html',
  styleUrls: ['./edit-voice-profile.component.css']
})
export class EditVoiceProfileComponent implements OnInit {
  languageList: any;
  content: any;
  categoryList: any;
  accentList: any;
  voiceRoleList: any;
  voiceAgeList: any;
  recordingSetupList: any;
  styleList: any;

  //Fetch User log in details
  currentUser: any;
  contact: any;
  id: any;
  userId: any;

  constructor(private userService: UserService, private toastr: ToastrService, private router: Router, 
    private token: TokenStorageService) { }

  ngOnInit(): void {
    this.getLanguageList();
    this.getCategoryList();
    this.getRecordingSetupList();
    this.getVoiceAgeList();
    this.getAccentList();
    this.getVoiceRoleList();
    this.getStyleList();
    
    //Fetching UserLogIn Details.
    this.currentUser = this.token.getUser();
    this.contact = this.token.getUser().contact;
    this.id = this.token.getUser().id;
    
    $( function() {
      $( "#popupDatepicker" ).datepicker();
    } );
  
    $(function () {
      "use strict";
      var configChosen: any = {
        '.chosen-select'           : {},
        '.chosen-select-deselect'  : {allow_single_deselect:true},
        '.chosen-select-no-single' : {disable_search_threshold:10},
        '.chosen-select-no-results': {no_results_text:'Nothing Found'},
        '.chosen-select-width'     : {width:"100%"}
      }
      for (var selector in configChosen) {
        $(selector).chosen(configChosen[selector]);
      }
    });

  }

  isEditVoice = true;
  isEditVoiceFailed = true;
  EditVoiceErrorMessage = '';


  editVoiceProfileForm: any = {
    language: null,
    category_name: null,
    recordingSetup: null,
    age_range: null,
    accent: null,
    role_name: null,
    style_name: null
  };

  editVoiceProfile(): void {
    const { language, category_name, recordingSetup, age_range, accent, role_name, style_name} = this.editVoiceProfileForm;
    this.userService.editVoiceProfile(language, category_name, recordingSetup, age_range, accent, role_name, style_name, this.userId = this.id).subscribe(
      data =>{
        this.isEditVoice = true;
        this.toastr.success('Voice Details Update successfully','');
        this.router.navigateByUrl('/pricedelivery');
      },
      err => {
        this.toastr.error('Please fill all the mandatory fields...','');
        this.EditVoiceErrorMessage = err.error.message;
        this.isEditVoiceFailed = false;
      }
    );  
    }

  


  getLanguageList() {
    this.userService.getLanguageList().subscribe(data => {
      this.languageList = data;
      if (this.languageList !== null) {
        this.languageList.forEach((element: { language: string; }) => {
          // this.Countries.country === element.country;
          // this.Countries.currencyCode = element.currencyCode;
          // this.Countries.iso = element.iso;
        });
      }
    },
      err => {
        this.content = JSON.parse(err.error).message;
        console.log(this.content);
      }
    );
  }

  getCategoryList() {
    this.userService.getCategoryList().subscribe(data => {
      this.categoryList = data;
      if (this.categoryList !== null) {
        this.categoryList.forEach((element: { category_name: string; }) => {
          // this.Countries.country === element.country;
          // this.Countries.currencyCode = element.currencyCode;
          // this.Countries.iso = element.iso;
        });
      }
    },
      err => {
        this.content = JSON.parse(err.error).message;
        console.log(this.content);
      }
    );
  }

  getRecordingSetupList() {
    this.userService.getRecordingSetupList().subscribe(data => {
      this.recordingSetupList = data;
      if (this.recordingSetupList !== null) {
        this.recordingSetupList.forEach((element: { recording_setup: string; }) => {
          // this.Countries.country === element.country;
          // this.Countries.currencyCode = element.currencyCode;
          // this.Countries.iso = element.iso;
        });
      }
    },
      err => {
        this.content = JSON.parse(err.error).message;
        console.log(this.content);
      }
    );
  }


  getVoiceAgeList() {
    this.userService.getVoiceAgeList().subscribe(data => {
      this.voiceAgeList = data;
      if (this.voiceAgeList !== null) {
        this.voiceAgeList.forEach((element: { age_range: string; }) => {
          // this.Countries.country === element.country;
          // this.Countries.currencyCode = element.currencyCode;
          // this.Countries.iso = element.iso;
        });
      }
    },
      err => {
        this.content = JSON.parse(err.error).message;
        console.log(this.content);
      }
    );
  }

  getVoiceRoleList() {
    this.userService.getVoiceRoleList().subscribe(data => {
      this.voiceRoleList = data;
      if (this.voiceRoleList !== null) {
        this.voiceRoleList.forEach((element: { role_name: string; }) => {
          // this.Countries.country === element.country;
          // this.Countries.currencyCode = element.currencyCode;
          // this.Countries.iso = element.iso;
        });
      }
    },
      err => {
        this.content = JSON.parse(err.error).message;
        console.log(this.content);
      }
    );
  }

  getAccentList() {
    this.userService.getAccentList().subscribe(data => {
      this.accentList = data;
      if (this.accentList !== null) {
        this.accentList.forEach((element: { accent: string; }) => {
          // this.Countries.country === element.country;
          // this.Countries.currencyCode = element.currencyCode;
          // this.Countries.iso = element.iso;
        });
      }
    },
      err => {
        this.content = JSON.parse(err.error).message;
        console.log(this.content);
      }
    );
  }

  getStyleList() {
    this.userService.getStyleList().subscribe(data => {
      this.styleList = data;
      if (this.styleList !== null) {
        this.styleList.forEach((element: { style_name: string; }) => {
          // this.Countries.country === element.country;
          // this.Countries.currencyCode = element.currencyCode;
          // this.Countries.iso = element.iso;
        });
      }
    },
      err => {
        this.content = JSON.parse(err.error).message;
        console.log(this.content);
      }
    );
  }


}
