<!-- Banner -->

<div class="dashboardbanner">
  <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
  <div class="bannerheading">Price Delivery Time & Availability</div>
</div>

<div class="fullwidth bg_purple">
  <!--bof: myrecording-->
  <div class="w1200">
    <div class="pointgrid">
      <div class="pointcols">
        <span><i class="active" routerLink="/editprofile"></i></span>
        <strong>Personal Info</strong>
      </div>
      <div class="pointcols">
        <span><i class="active" routerLink="/editvoice"></i></span>
        <strong>Voice Profile</strong>
      </div>
      <div class="pointcols">
        <span><i class="active" routerLink="/pricedelivery"></i></span>
        <strong>Price, Delivery time &amp; Availability</strong>
      </div>
      <div class="pointcols">
        <span><i routerLink="/equipment"></i></span>
        <strong>Equipments &amp; Experience</strong>
      </div>
      <div class="pointcols">
        <span><i routerLink="/voicesample"></i></span>
        <strong>Voice &amp; Sample</strong>
       </div>   
    </div>
    <div class="wedget">
      <!-- bof:wedgetleft -->
      <app-sidebar></app-sidebar>
      <!-- bof:wedgetright -->
      <div class="wedgetright">
        <div class="wedgetform">
          <h3>Price, Delivery time &amp; Availability</h3>
          <!--bof:formsection-->
          <div class="form1 delivery_form">
            <form class="formpanel" [formGroup]="userForm" (ngSubmit)="submit(userForm.value)">
              <!-- <div formGroupName="userForm"> -->
              <div formArrayName="price_data">
                <div let i="index">
                  <div>
                    <label>Corporate IVR</label>
                    <div class="form_row">
                      <div class="formwrap">
                        <p>1-5 prompts</p>
                        <input type="text" [(ngModel)]="Data.value1" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>6-15 prompts</p>
                        <input type="text" [(ngModel)]="Data.value2" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>16-50 prompts</p>
                        <input type="text" [(ngModel)]="Data.value77" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Above 51 prompts</p>
                        <input type="text" [(ngModel)]="Data.value78" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p class="tat">TAT</p>
                        <input type="text" [(ngModel)]="Data.value3" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                    </div>
                    <label>E-learning</label>
                    <div class="form_row">
                      <div class="formwrap">
                        <p>Minimum order value upto 15 minutes</p>
                        <input type="text" [(ngModel)]="Data.value4" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for 16-300 minutes</p>
                        <input type="text" [(ngModel)]="Data.value5" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for 301-600 minutes</p>
                        <input type="text" [(ngModel)]="Data.value6" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for 601 minutes & beyond</p>
                        <input type="text" [(ngModel)]="Data.value7" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p class="tat">TAT</p>
                        <input type="text" [(ngModel)]="Data.value8" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                    </div>

                    <label>Radio Spot</label>
                    <div class="form_row">
                      <div class="formwrap">
                        <p>Price for 10 second spot</p>
                        <input type="text" [(ngModel)]="Data.value9" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for 20 second spot</p>
                        <input type="text" [(ngModel)]="Data.value10" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for 30 second spot</p>
                        <input type="text" [(ngModel)]="Data.value11" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for 60 second spot</p>
                        <input type="text" [(ngModel)]="Data.value12" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p class="tat">TAT</p>
                        <input type="text" [(ngModel)]="Data.value13" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                    </div>
                    <label>Audiobooks</label>
                    <div class="form_row">
                      <div class="formwrap">
                        <p>Minimum order value upto 30 minutes</p>
                        <input type="text" [(ngModel)]="Data.value14" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for upto 60 minutes</p>
                        <input type="text" [(ngModel)]="Data.value15" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for upto 90 minutes</p>
                        <input type="text" [(ngModel)]="Data.value16" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for upto 120 minutes</p>
                        <input type="text" [(ngModel)]="Data.value17" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for upto 150 minutes</p>
                        <input type="text" [(ngModel)]="Data.value18" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for succeeding hours</p>
                        <input type="text" [(ngModel)]="Data.value19" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p class="tat">TAT</p>
                        <input type="text" [(ngModel)]="Data.value20" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                    </div>

                    <label>Jingle</label>
                    <div class="form_row">
                      <div class="formwrap">
                        <p>Price for 10 second jingle</p>
                        <input type="text" [(ngModel)]="Data.value21" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for 20 second jingle</p>
                        <input type="text" [(ngModel)]="Data.value22" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for 30 second jingle</p>
                        <input type="text" [(ngModel)]="Data.value23" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for 60 second jingle</p>
                        <input type="text" [(ngModel)]="Data.value24" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p class="tat">TAT</p>
                        <input type="text" [(ngModel)]="Data.value25" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                    </div>

                    <label>Mimicry</label>
                    <div class="form_row">
                      <div class="formwrap">
                        <p>Mimicry of Bollywood Celebrities</p>
                        <input type="text" [(ngModel)]="Data.value26" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Mimicry of Hollywood Celebrities</p>
                        <input type="text" [(ngModel)]="Data.value27" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Mimicry of Cartoon/Animation characters</p>
                        <input type="text" [(ngModel)]="Data.value28" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Mimicry of an animal/bird/reptiles</p>
                        <input type="text" [(ngModel)]="Data.value29" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p class="tat">TAT</p>
                        <input type="text" [(ngModel)]="Data.value30" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                    </div>

                    <label>Audio recording for explainer Video</label>
                    <div class="form_row">
                      <div class="formwrap">
                        <p>Minimum order value 200 words </p>
                        <input type="text" [(ngModel)]="Data.value31" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>500 words words explainer video</p>
                        <input type="text" [(ngModel)]="Data.value32" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>1000 words words explainer video</p>
                        <input type="text" [(ngModel)]="Data.value33" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>2000 words words explainer video</p>
                        <input type="text" [(ngModel)]="Data.value34" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for succeeding words</p>
                        <input type="text" [(ngModel)]="Data.value35" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p class="tat">TAT</p>
                        <input type="text" [(ngModel)]="Data.value36" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                    </div>


                    <label>TVC</label>
                    <div class="form_row">
                      <div class="formwrap">
                        <p>Price for 10 seconds TVC</p>
                        <input type="text" [(ngModel)]="Data.value37" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for 20 seconds TVC</p>
                        <input type="text" [(ngModel)]="Data.value38" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for 30 seconds TVC</p>
                        <input type="text" [(ngModel)]="Data.value39" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for 40 seconds TVC</p>
                        <input type="text" [(ngModel)]="Data.value40" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for 50 seconds TVC</p>
                        <input type="text" [(ngModel)]="Data.value41" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for 60+ seconds TVC</p>
                        <input type="text" [(ngModel)]="Data.value42" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p class="tat">TAT</p>
                        <input type="text" [(ngModel)]="Data.value43" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                    </div>

                    <label>Advertisement (Social media, internal & other uses)</label>
                    <div class="form_row">
                      <div class="formwrap">
                        <p>Price for 10 seconds Ad</p>
                        <input type="text" [(ngModel)]="Data.value44" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for 20 seconds Ad</p>
                        <input type="text" [(ngModel)]="Data.value45" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for 30 seconds Ad</p>
                        <input type="text" [(ngModel)]="Data.value46" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for 40 seconds Ad</p>
                        <input type="text" [(ngModel)]="Data.value47" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for 50 seconds Ad</p>
                        <input type="text" [(ngModel)]="Data.value48" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for 60+ seconds Ad</p>
                        <input type="text" [(ngModel)]="Data.value49" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p class="tat">TAT</p>
                        <input type="text" [(ngModel)]="Data.value50" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                    </div>

                    <label>Documentary</label>
                    <div class="form_row">
                      <div class="formwrap">
                        <p>Upto 600 words</p>
                        <input type="text" [(ngModel)]="Data.value51" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>600-1200 words</p>
                        <input type="text" [(ngModel)]="Data.value52" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>1201-1800 words</p>
                        <input type="text" [(ngModel)]="Data.value53" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>1801-2400 words</p>
                        <input type="text" [(ngModel)]="Data.value54" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>2401-3000 words</p>
                        <input type="text" [(ngModel)]="Data.value55" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>30001+ words</p>
                        <input type="text" [(ngModel)]="Data.value56" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p class="tat">TAT</p>
                        <input type="text" [(ngModel)]="Data.value57" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                    </div>

                    <label>Animation</label>
                    <div class="form_row">
                      <div class="formwrap">
                        <p>Minimum order value upto 200 words</p>
                        <input type="text" [(ngModel)]="Data.value58" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>500 words voice recording</p>
                        <input type="text" [(ngModel)]="Data.value59" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>1000 words voice recording</p>
                        <input type="text" [(ngModel)]="Data.value60" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>2000 words voice recording</p>
                        <input type="text" [(ngModel)]="Data.value61" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for succeeding words</p>
                        <input type="text" [(ngModel)]="Data.value62" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p class="tat">TAT</p>
                        <input type="text" [(ngModel)]="Data.value63" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                    </div>

                    <label>Voice assistance</label>
                    <div class="form_row">
                      <div class="formwrap">
                        <p>200 words voice assistance</p>
                        <input type="text" [(ngModel)]="Data.value64" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>500 words voice assistance</p>
                        <input type="text" [(ngModel)]="Data.value65" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>1000 words voice assistance</p>
                        <input type="text" [(ngModel)]="Data.value66" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>2000 words voice assistance</p>
                        <input type="text" [(ngModel)]="Data.value67" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for succeeding words</p>
                        <input type="text" [(ngModel)]="Data.value68" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p class="tat">TAT</p>
                        <input type="text" [(ngModel)]="Data.value69" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                    </div>

                    <label>Podcast</label>
                    <div class="form_row">
                      <div class="formwrap">
                        <p>Minimum order value upto 30 minutes</p>
                        <input type="text" [(ngModel)]="Data.value70" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for 60 minutes</p>
                        <input type="text" [(ngModel)]="Data.value71" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for 90 minutes</p>
                        <input type="text" [(ngModel)]="Data.value72" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for 120 minutes</p>
                        <input type="text" [(ngModel)]="Data.value73" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for 150 minutes</p>
                        <input type="text" [(ngModel)]="Data.value74" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Price for succeeding hours</p>
                        <input type="text" [(ngModel)]="Data.value75" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p class="tat">TAT</p>
                        <input type="text" [(ngModel)]="Data.value76" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                    </div>
                    <div class="formwrapfull">
                      <div class="rowavaility">
                        <label>Availability</label>
                        <p>Select your working days and Hours</p>
                        <div class="row">
                          <div class="available24x7">Available 24x7</div>
                          <div class="availablerightpart">Are you a workaholic like us? If yes select this option
                            <label class="availityswitch textmiddle active">
                              <input type="checkbox" [(ngModel)]="availabilityForm.is24Available"
                                [ngModelOptions]="{standalone: true}"><span class="slider round r2"></span></label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="formwrapfull">
                      <div class="rowavaility">
                        <label>Day Availability</label>
                        <p>Select your working days</p>
                        <div class="row">
                          <div class="available24x7">Available 24x7</div>
                          <div class="availablerightpart">Choose this if you don't take a weekly off. <label
                              class="availityswitch textmiddle active">
                              <input type="checkbox" [(ngModel)]="availabilityForm.weeklyOff"
                                [ngModelOptions]="{standalone: true}"><span class="slider round r2"></span></label>
                          </div>
                        </div>
                      </div>
                    </div> -->

                    <div class="formwrapfull">
                      <div class="rowavaility">
                        <label>Day Availability</label>
                        <p>Select your working days</p>
                        <div class="rowscroll">
                          <div class="row">
                            <div class="col">Sun <label class="availityswitch textmiddle">
                                <input type="checkbox" [(ngModel)]="availabilityForm.daySunday"
                                  [ngModelOptions]="{standalone: true}"><span class="slider round"></span></label></div>
                            <div class="col">Mon <label class="availityswitch textmiddle">
                                <input type="checkbox" [(ngModel)]="availabilityForm.dayMonday"
                                  [ngModelOptions]="{standalone: true}"><span class="slider round"></span></label></div>
                            <div class="col">Tue <label class="availityswitch textmiddle">
                                <input type="checkbox" [(ngModel)]="availabilityForm.dayTuesday"
                                  [ngModelOptions]="{standalone: true}"><span class="slider round"></span></label></div>
                            <div class="col">Wed <label class="availityswitch textmiddle">
                                <input type="checkbox" [(ngModel)]="availabilityForm.dayWednesday"
                                  [ngModelOptions]="{standalone: true}"><span class="slider round"></span></label></div>
                            <div class="col">Thu <label class="availityswitch textmiddle">
                                <input type="checkbox" [(ngModel)]="availabilityForm.dayThursday"
                                  [ngModelOptions]="{standalone: true}"><span class="slider round"></span></label></div>
                            <div class="col">Fri <label class="availityswitch textmiddle">
                                <input type="checkbox" [(ngModel)]="availabilityForm.dayFriday"
                                  [ngModelOptions]="{standalone: true}"><span class="slider round"></span></label></div>
                            <div class="col">Sat <label class="availityswitch textmiddle">
                                <input type="checkbox" [(ngModel)]="availabilityForm.daySaturday"
                                  [ngModelOptions]="{standalone: true}"><span class="slider round"></span></label></div>
                          </div>
                        </div>
                      </div>

                      <div class="formwrapfull">
                        <div class="rowavaility">
                          <label>Time Availability</label>
                          <p>Select your working Hours</p>
                          <div class="row">
                            <div class="available24x7">Available 24x7</div>
                            <div class="availablerightpart">Choose this if you don't take a weekly hours <label
                                class="availityswitch textmiddle active">
                                <input type="checkbox" [(ngModel)]="availabilityForm.timeAvailability"
                                  [ngModelOptions]="{standalone: true}"><span class="slider round r2"></span></label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p>Working hours</p>
                      <div class="form_row">
                        <div class="formwrap">
                          <input class="start" type="time" [(ngModel)]="availabilityForm.startTime"
                            [ngModelOptions]="{standalone: true}">
                        </div>
                        <div class="formwrap">
                          <input class="end" type="time" [(ngModel)]="availabilityForm.endTime"
                            [ngModelOptions]="{standalone: true}">
                        </div>
                      </div>
                      <div class="formwrapfull lastdiv">
                        <div class="submitdiv">
                          <input type="submit" value="Save and Next" (click)="data()">
                          <i class="fa fa-angle-right" aria-hidden="true"></i>
                        </div>
                        <strong style="top: 0;">Items marked with <span>*</span> are mendatory</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- </div> -->
            </form>
            <!--eof:formsection-->
          </div>
          <!--eof: form-->
        </div>
      </div>
    </div>
    <!--eof: myrecording-->