import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  isSubscribe = true;
  isSubscribeFailed = true;
  SubscribeErrorMessage = '';

  constructor(private userService: UserService, private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {
  }

  subscribeForm: any = {
    email: null,
  }

  subscribe(){
    const {email} = this.subscribeForm;
    this.userService.subscribeUs(email).subscribe(
      data => {
        console.log(data);
        this.isSubscribe = true;
        this.toastr.success('ThankYou for subscribe our newsletter','');
      },
      err => {
        this.toastr.error('Error','');
        this.SubscribeErrorMessage = err.error.message;
        this.isSubscribeFailed = false;
      }

    );
    }

    about(){
      window.scroll(0,0);
      this.router.navigate(['/about']);
    }

    policy(){
      window.scroll(0,0);
      this.router.navigate(['/policy']);
    }

    terms(){
      window.scroll(0,0);
      this.router.navigate(['/terms']);
    }

    howwework(){
      window.scroll(0,0);
      this.router.navigate(['/howwework']);
    }

    contact(){
      window.scroll(0,0);
      this.router.navigate(['/contact']);
    }


}
